import { useNavigate } from "react-router-dom";
import { useTranslations, MessageKey } from "@properate/translations";
import {
  getSimplifiedPriority,
  IncidentClientSide,
  keysForAlarmPriority,
  SimplifiedAlarmPriority,
} from "@properate/common";
import { ColumnsType } from "antd/lib/table/interface";
import React, { ReactNode } from "react";
import { Tooltip } from "antd";
import { TableInfiniteScroll } from "@/components/TableInfiniteScroll/TableInfiniteScroll";
import { useIncidentsData } from "@/pages/alarmSystem/incidents/utils";
import { ReactComponent as Error } from "@/pages/alarms/icons/error.svg";
import { ReactComponent as Warning } from "@/pages/alarms/icons/warning.svg";
import { ReactComponent as Info } from "@/pages/alarms/icons/info.svg";
import { DraggableCard } from "../draggable/DraggableCard";
import { WidgetHeader } from "../widget-components/WidgetHeader";
interface Props {
  id: number;
  width: number;
  height: number;
  onClickRemoveButton: () => unknown;
}

export const SEVERITY: Record<SimplifiedAlarmPriority, ReactNode> = {
  [SimplifiedAlarmPriority.Critical]: <Error />,
  [SimplifiedAlarmPriority.High]: <Error />,
  [SimplifiedAlarmPriority.Medium]: <Warning />,
  [SimplifiedAlarmPriority.Low]: <Info />,
};

export function IncidentsWidget({
  id,
  width,
  height,
  onClickRemoveButton,
}: Props) {
  const t = useTranslations();

  const navigate = useNavigate();
  const headerHeight = 46;
  const { incidents, onLoadMore, isLoading } = useIncidentsData({
    id: id.toString(),
    filters: ["only_active"],
    sort: { property: "start", order: "desc" },
  });

  const onClick = (incidentId: string) => {
    navigate(`/asset/${id}/incidents/${incidentId}`);
  };

  const columns = [
    {
      title: t("incidents.table.severity"),
      dataIndex: "priority",
      key: "priority",
      width: 46,
      render: (severity) => {
        const priority = getSimplifiedPriority(severity);
        const text = t(keysForAlarmPriority[priority] as MessageKey);
        return <Tooltip title={text}>{SEVERITY[priority]}</Tooltip>;
      },
    },
    {
      title: t("incidents.table.name"),
      key: "name",
      dataIndex: "name",
      sorter: true,
    },
  ] as ColumnsType<IncidentClientSide>;

  return (
    <DraggableCard
      bordered={false}
      style={{ width, height }}
      styles={{ body: { padding: 24 } }}
      title={
        <WidgetHeader
          text={t("dashboard.widgets.incidents.title")}
          onClickRemoveButton={onClickRemoveButton}
          isDraggable
        />
      }
    >
      <div>
        {!!incidents?.length && (
          <TableInfiniteScroll<IncidentClientSide>
            showHeader={false}
            columns={columns}
            dataSource={incidents}
            height={height - 48 - 17 - headerHeight}
            onReachedEndOfPage={onLoadMore}
            rowKey="id"
            loading={isLoading}
            onRow={(record) => ({
              onClick: () => onClick(record.id!),
            })}
          />
        )}
      </div>
    </DraggableCard>
  );
}
