import { FunctionComponent } from "react";
import { Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { useTranslations, MessageKey } from "@properate/translations";
import { BB_KEYS } from "../../helpers/tfm";
const Option = Select.Option;

interface Props {
  onChange: (value: string) => any;
  value: string;
  hidden?: string[];
}

export const BBSelect: FunctionComponent<Props> = ({
  onChange,
  value,
  hidden = [],
}) => {
  const t = useTranslations();

  const handleOptionFilter = (
    search: string,
    item: DefaultOptionType | undefined,
  ) => {
    return (
      item !== undefined &&
      item.children !== undefined &&
      String(item.children).toLowerCase().indexOf(search.toLowerCase()) >= 0
    );
  };

  return (
    <Select
      id="bb"
      showAction={["focus", "click"]}
      showSearch
      style={{ width: "100%" }}
      value={value}
      onChange={onChange}
      filterOption={handleOptionFilter}
      size="small"
      popupMatchSelectWidth={170}
    >
      {BB_KEYS.filter((bb) => !hidden.includes(bb)).map((bb) => (
        <Option key={bb} value={bb}>
          {bb} ({t(`tfm.bb-descriptions.${bb}` as MessageKey)})
        </Option>
      ))}
    </Select>
  );
};
