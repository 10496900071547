import { Button, message, Tooltip } from "antd";
import { useUser } from "@properate/auth";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import useSWRMutation from "swr/mutation";
import { useBuildingModulesAccess } from "@/services/featureAccess/useAccessModuleFeatureAccess";
import { AccessModules } from "@/features/organizationAdmin";
import { AnalysisCreateRedirectButtonProps } from "@/utils/types";
import { createSimpleAnalysis } from "@/pages/common/utils";

export function AnalysisCreateRedirectButton({
  buildingId,
  timeseries,
  auxiliaryTimeseries,
  yAxisSettings,
  style,
  colors,
}: AnalysisCreateRedirectButtonProps) {
  const user = useUser();
  const navigate = useNavigate();
  const { accessModules } = useBuildingModulesAccess();
  const t = useTranslations();

  const { trigger, isMutating } = useSWRMutation(
    {
      buildingId,
      timeseries,
      auxiliaryTimeseries,
      yAxisSettings,
      userEmail: user.email,
      colors,
    },
    (params) => {
      return createSimpleAnalysis(params);
    },
    {
      onSuccess: (data) => {
        navigate(data);
      },
      onError: (error) => {
        console.error(error);

        message.error(
          t("common.error.something-went-wrong", {
            error: error.message,
          }),
        );
      },
    },
  );

  const hasUserAccessToAnalysis = accessModules.includes(
    AccessModules.analysis,
  );

  return (
    hasUserAccessToAnalysis && (
      <Tooltip
        title={t("analysis.timeseries-graph.create-analysis-button-text")}
      >
        <Button onClick={() => trigger()} loading={isMutating} style={style}>
          {t("analysis.timeseries-graph.create-analysis-button-text")}
        </Button>
      </Tooltip>
    )
  );
}
