import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Timeseries } from "@cognite/sdk";
import {
  AutoSizedSimpleGraph,
  SettingsTimeseriesSimple,
  SimpleGraphContextProvider,
} from "@/features/analysis";
import {
  useGraphHighlightsForEvents,
  useAlarmDetailsTimeSpan,
  useAlarmGraphLegendData,
  useTimeseries,
} from "../../../details/hooks";
import { useFormValue } from "../../../details/FormContext/hooks";
import { getColor } from "./utils";
import AlarmTimeseriesEmpty from "./AlarmTimeseriesEmpty";
import { AlarmTimeseriesNavigator } from "./AlarmTimeseriesNavigator";
import { AlarmTimeseriesGraphLayout } from "./AlarmTimeseriesGraphLayout";

type Props = {
  tsOneName: string;
  tsTwoName: string;
  tsTwoMathExpression?: string;
};

function TwoTimeseriesGraphImpl({
  tsOneName,
  tsTwoName,
  tsTwoMathExpression,
}: Props) {
  const timeSpan = useAlarmDetailsTimeSpan();
  const highlights = useGraphHighlightsForEvents();
  const [tsOneId] = useFormValue<number>(tsOneName);
  const [tsTwoId] = useFormValue<number>(tsTwoName);
  const themeContext = useContext(ThemeContext);
  const { timeseries: tsOne } = useTimeseries({
    timeseriesId: tsOneId,
  });
  const { timeseries: tsTwo } = useTimeseries({
    timeseriesId: tsTwoId,
  });

  const colorOne = getColor(0, themeContext);
  const colorTwo = getColor(1, themeContext);

  const legendData = useAlarmGraphLegendData({
    timeseriesList: [tsOne, tsTwo],
    timeseriesSettings: {
      [tsOneId]: {
        color: colorOne,
        labelKey: "ui.value",
      },
      [tsTwoId]: {
        color: colorTwo,
        labelKey: "ui.value",
      },
    },
  });

  function makeSettingsObject(
    tsId: number,
    color: string,
    unit: string,
    mathExpression?: string,
  ) {
    return {
      id: tsId,
      hidden: false,
      color,
      unitSelected: unit,
      mathExpression,
    };
  }

  const timeseries: SettingsTimeseriesSimple[] = [];
  if (tsOneId) {
    timeseries.push(makeSettingsObject(tsOneId, colorOne, tsOne?.unit ?? ""));
  }
  if (tsTwoId) {
    timeseries.push(
      makeSettingsObject(
        tsTwoId,
        colorTwo,
        tsTwo?.unit ?? "",
        tsTwoMathExpression,
      ),
    );
  }

  function renderGraph() {
    return (
      <AutoSizedSimpleGraph
        interactive
        timeSpan={timeSpan}
        highlights={highlights}
        settingsTimeseriesList={timeseries}
        mergeUnits
        legendData={legendData}
      />
    );
  }

  function renderNavigator() {
    if (!tsOne && !tsTwo) {
      return <AlarmTimeseriesEmpty />;
    }
    const tslist =
      tsOne && tsTwo
        ? [
            {
              timeseries: tsOne as Timeseries,
              color: colorOne,
            },
            {
              timeseries: tsTwo as Timeseries,
              color: colorTwo,
            },
          ]
        : [
            {
              timeseries: tsOne as Timeseries,
              color: colorOne,
            },
          ];
    return <AlarmTimeseriesNavigator timeseriesWithDataPointsList={tslist} />;
  }

  if (timeseries.length === 0) {
    return <AlarmTimeseriesEmpty />;
  }

  return (
    <AlarmTimeseriesGraphLayout
      graph={renderGraph()}
      navigator={renderNavigator()}
    />
  );
}

export default function TwoTimeseriesGraph(props: Props) {
  return (
    <SimpleGraphContextProvider>
      <TwoTimeseriesGraphImpl {...props} />
    </SimpleGraphContextProvider>
  );
}
