import { Suspense, useEffect, useMemo, useState } from "react";
import { useLoaderData, useSubmit, useNavigate, Await } from "react-router-dom";
import { Button, Input, Space, Spin } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { Step } from "react-joyride";
import { DeleteOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { CompactContent } from "@/components/CompactContent";
import { useOnboarding } from "@/utils/onboarding";
import { useHelp } from "@/context/HelpContext";
import { JoyrideWrapper } from "@/components/JoyrideWrapper/JoyrideWrapper";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { FilePageData, Sort, UpdateFile } from "@/pages/fileType/types";
import { getUrl } from "@/pages/fileType/utils";
import { TranslationFunction } from "@/utils/types";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import SelectResourceButton from "@/pages/fileType/SelectResourceButton";
import SelectLabels from "./selectLabels";
import UploadButton from "./UploadFile";
import { FileTable } from "./FileTable/FileTable";

const getSteps = (t: TranslationFunction): Step[] => [
  {
    placement: "top",
    target: ".file-new",
    content: t("files.joyride.upload-document"),
  },
  {
    placement: "top",
    target: ".anticon-filter",
    content: t("files.joyride.filter-document"),
  },
  {
    placement: "top",
    target: ".ant-table-header",
    content: t("files.joyride.file-info"),
  },
];

type Props = {
  hidden?: boolean;
};

export function Files({ hidden = false }: Props) {
  const t = useTranslations();
  const building = useCurrentBuilding();
  useBuildingPageTitle(t("files.title"));
  const page = useLoaderData() as FilePageData;
  const submit = useSubmit();
  const user = useUser();
  const navigate = useNavigate();
  const [onboardingSteps, setOnboardingStepCompleted] = useOnboarding(
    getSteps(t),
  );
  const { setHelp } = useHelp();

  const doFilter = ({
    labels = page.labels,
    fileTypes = page.fileTypes,
    resource = page.resource,
  }: {
    labels?: string[];
    fileTypes?: string[];
    resource?: string;
  }) => {
    navigate(
      getUrl(
        page.id,
        page.search,
        labels,
        fileTypes,
        page.sort,
        page.hidden,
        resource,
      ),
    );
  };

  const doSort = (sort: Sort) => {
    navigate(
      getUrl(
        page.id,
        page.search,
        page.labels,
        page.fileTypes,
        sort,
        page.hidden,
        page.resource,
      ),
    );
  };

  const setSearchDebounced = useMemo(
    () =>
      debounce(
        (search: string) =>
          navigate(
            getUrl(
              page.id,
              search,
              page.labels,
              page.fileTypes,
              page.sort,
              page.hidden,
              page.resource,
            ),
          ),
        300,
      ),
    [
      navigate,
      page.fileTypes,
      page.hidden,
      page.id,
      page.labels,
      page.resource,
      page.sort,
    ],
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  useEffect(() => {
    setHelp({
      title: t("files.title"),
      content: (
        <>
          <p>
            {t("files.hint-search")}
            <br />
            <p />
            {t("files.hint-filter")}
          </p>
        </>
      ),
    });
  }, [setHelp, t]);

  const patchFiles = (ids: number[]) => {
    submit(
      { ids },
      {
        method: "patch",
        encType: "application/json",
      },
    );
  };

  const purgeFiles = (ids: number[]) => {
    submit(
      { ids },
      {
        method: "delete",
        encType: "application/json",
      },
    );
  };

  const updateFiles = async (changes: UpdateFile[]) => {
    submit(
      { changes },
      {
        method: "post",
        encType: "application/json",
      },
    );
  };

  return (
    <>
      <PageHeader
        title={hidden ? t("files.removed-files") : t("files.title")}
        extra={
          <Space direction="horizontal">
            <Input
              type="search"
              placeholder={t("files.search-by-name")}
              defaultValue={page.search}
              onChange={(event) =>
                setSearchDebounced(event.currentTarget.value)
              }
              style={{
                minWidth: 200,
                width: 360,
              }}
            />
            <SelectResourceButton
              id={building.id}
              system={page.resource}
              setFilter={doFilter}
            />
            <SelectLabels
              labels={page.labels}
              setFilter={doFilter}
              fileTypes={page.fileTypes}
            />
            <UploadButton />
            <Button
              onClick={() => {
                setSelectedRowKeys([]);
                navigate(
                  hidden
                    ? `/asset/${page.id}/files`
                    : `/asset/${page.id}/trash`,
                );
              }}
              disabled={user.isViewer}
              icon={hidden ? <UnorderedListOutlined /> : <DeleteOutlined />}
              danger={!hidden}
            >
              {hidden ? t("files.show-files") : t("files.show-removed-files")}
            </Button>
          </Space>
        }
      />
      <CompactContent>
        <Suspense
          fallback={
            <Spin
              style={{
                height: "100%",
                width: "100%",
                display: "grid",
                placeContent: "center",
              }}
            />
          }
        >
          <Await
            resolve={page.files}
            errorElement={<p>{t("files.error-loading-files")}</p>}
          >
            {(properateFiles) => (
              <FileTable
                selectedLabels={page.labels}
                selectedResource={undefined}
                id={page.id}
                data={properateFiles}
                {...(hidden
                  ? { onRestoreFiles: patchFiles, onPurgeFiles: purgeFiles }
                  : {
                      onDeleteFiles: patchFiles,
                      onUpdateFiles: updateFiles,
                    })}
                selectedRowKeys={selectedRowKeys}
                onSelectedRowsChange={setSelectedRowKeys}
                sort={page.sort}
                onSort={doSort}
              />
            )}
          </Await>
        </Suspense>
        {onboardingSteps.length > 0 && (
          <JoyrideWrapper
            content={onboardingSteps[0]}
            onClose={setOnboardingStepCompleted}
          />
        )}
      </CompactContent>
    </>
  );
}
