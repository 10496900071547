import { PanZoomApi } from "@sasza/react-panzoom/types/types";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import PanZoom from "@sasza/react-panzoom";
import { SchemaType, SchemaTypes } from "@properate/common";
import { PanZoomAreaApiProvider } from "@/components/PanZoom/PanZoomAreaApiContext";
import {
  OnDropParams,
  PanZoomAreaDnD,
} from "@/components/PanZoom/PanZoomAreaDnD";
import { useSchema, useSchemaList } from "@/services/schemas";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import {
  PanZoomAreaLinkType,
  PanZoomAreaLink,
} from "@/components/PanZoom/PanZoomAreaLink";
import { PanZoomAreaInteractionContext } from "@/components/PanZoom/PanZoomAreaInteractionContext";

const Wrapper = styled.div`
  .react-panzoom {
    touch-action: auto !important;

    .react-panzoom__in {
      pointer-events: auto !important;
    }
  }
`;

const Content = styled.div`
  border: 1px dashed rgba(0, 0, 0, 0.25);

  &[data-zooming="true"] svg text {
    display: none;
  }
`;

type Props = {
  type?: SchemaTypes;
  isEditing: boolean;
  disableZoom: boolean;
  snapshotId: string;
  schema: SchemaType;
  children: ReactNode;
};

export function SchemaViewPanZoom({
  type = "technicalSchema",
  isEditing,
  schema,
  snapshotId,
  disableZoom,
  children,
}: Props) {
  const [isZooming, setIsZooming] = useState(false);
  const isZoomingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const apiRef = useRef<PanZoomApi | null>(null);
  const [inResizingMode, setInResizingMode] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const buildingId = useCurrentBuildingId();
  const links = schema.links ?? [];
  const [draft, setDraft] = useState<PanZoomAreaLinkType | null>(null);

  useEffect(() => {
    setInResizingMode(false);
    setIsMoving(false);
  }, [isEditing]);

  const { list: schemas } = useSchemaList(type, buildingId, [
    "name",
    "snapshotId",
    "rootAssetId",
  ]);

  const { update: updateSchema } = useSchema(type, snapshotId);

  const schemaOptions = useMemo(() => {
    return schemas.map((schema) => ({
      label: schema.name,
      value: schema.snapshotId as string,
    }));
  }, [schemas]);

  const getLinkHref = useCallback(
    (link: PanZoomAreaLinkType) =>
      `/asset/${buildingId}/technicalSchema/${link.target}/view`,
    [buildingId],
  );

  async function handleDrop(params: OnDropParams) {
    const width = 100;
    const height = 100;

    const newLink = {
      id: crypto.randomUUID(),
      target: "",
      position: {
        x: params.x - width / 2,
        y: params.y - height / 2,
      },
      size: {
        width,
        height,
      },
    };

    setDraft(newLink);

    try {
      await updateSchema({
        ...schema,
        links: [...links, newLink],
      });
    } finally {
      setDraft(null);
    }
  }

  async function handleChange(target: PanZoomAreaLinkType) {
    await updateSchema({
      ...schema,
      links: links.map((link) => (link.id === target.id ? target : link)),
    });
  }

  async function handleDelete(id: string) {
    await updateSchema({
      ...schema,
      links: links.filter((link) => link.id !== id),
    });
  }

  return (
    <PanZoomAreaApiProvider apiRef={apiRef}>
      <PanZoomAreaInteractionContext
        isEditing={isEditing}
        isMoving={isMoving}
        setIsMoving={setIsMoving}
        inResizingMode={inResizingMode}
        setInResizingMode={setInResizingMode}
      >
        <Wrapper>
          <PanZoomAreaDnD onDrop={handleDrop}>
            <PanZoom
              ref={apiRef}
              key={disableZoom ? "zoom-disabled" : "zoom-enabled"}
              disabled={inResizingMode}
              disabledZoom={disableZoom}
              onElementsChange={() => setIsMoving(true)}
              onContainerZoomChange={() => {
                if (isZoomingTimeoutRef.current) {
                  clearTimeout(isZoomingTimeoutRef.current);
                }
                setIsZooming(true);
                // Chrome has some problems when zooming in and out too fast, the text inside the SVG
                // gets misaligned. This timeout is used to hide the text while zooming.
                isZoomingTimeoutRef.current = setTimeout(() => {
                  setIsZooming(false);
                }, 250);
              }}
            >
              <Content data-zooming={isZooming ? "true" : "false"}>
                {children}
              </Content>
              {links.map((link) => (
                <PanZoomAreaLink
                  key={link.id}
                  link={link}
                  isDraft={false}
                  isEditing={isEditing}
                  targetOptions={schemaOptions}
                  getLinkHref={getLinkHref}
                  onChange={handleChange}
                  onDelete={handleDelete}
                />
              ))}
              {draft && <PanZoomAreaLink link={draft} isDraft />}
            </PanZoom>
          </PanZoomAreaDnD>
        </Wrapper>
      </PanZoomAreaInteractionContext>
    </PanZoomAreaApiProvider>
  );
}
