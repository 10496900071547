import { useUser } from "@properate/auth";
import { Node, useReactFlow } from "reactflow";
import { useState, useEffect } from "react";
import { ExternalId, Timeseries } from "@cognite/sdk";
import { Form, Button, Select, List, Space } from "antd";
import { LineChartOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { GraphModal } from "../../common/SchemaGraph/GraphModal";
import { GraphModalData } from "../types";
import {
  LargeNode,
  NodeInput,
  NodeInputLabel,
  Body,
  OutputHeader,
} from "./helpers/NodeComponents";
import { getNodeId, updateReactFlowNodeData } from "./helpers/Utils";
import { AuditLogButton } from "./components/AuditLogButton";
import TimeSeriesGraphButton from "./components/TimeseriesGraphButton";

const FormItem = Form.Item;

interface Props {
  externalIds: ExternalId[];
  priority: number;
  operationId: string;
}

export const getEmptySetpointsOutputNode = (): Node<Props> => {
  return {
    id: getNodeId("setpointsOutput"),
    type: "setpointsOutput",
    data: {
      externalIds: [],
      priority: 16,
      operationId: "write_setpoints",
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function SetpointsOutputNode(params: { id: string; data: Props }) {
  const t = useTranslations();
  const reactFlowInstance = useReactFlow();
  const currentBuildingId = useCurrentBuildingId();
  const user = useUser();

  const [isSetpointSelectionModalVisible, setIsSetpointSelectionModalVisible] =
    useState(false);
  const [selectedSetpoints, setSelectedSetpoints] = useState<Timeseries[]>([]);
  const [showSetpointData, setShowSetpointData] =
    useState<GraphModalData | null>(null);
  const [priority, setPriority] = useState(params.data.priority);

  useEffect(() => {
    updateReactFlowNodeData(
      reactFlowInstance,
      params.id,
      "externalIds",
      selectedSetpoints.map((setpoint) => {
        return { externalId: setpoint.externalId };
      }),
    );
  }, [params.id, reactFlowInstance, selectedSetpoints]);

  useEffect(() => {
    updateReactFlowNodeData(reactFlowInstance, params.id, "priority", priority);
  }, [params.id, reactFlowInstance, priority]);

  return (
    <>
      <LargeNode>
        <OutputHeader>
          {t("calculation-flow.node-types.write-to-several-setpoints")}
        </OutputHeader>
        <Body>
          <NodeInput inputId="a">
            <NodeInputLabel style={{ width: "100%" }}>
              <Form layout="vertical" disabled={user.isViewer}>
                <List
                  dataSource={params.data.externalIds}
                  renderItem={(externalId) => (
                    <List.Item>
                      {externalId.externalId}
                      <Space style={{ float: "right" }}>
                        <AuditLogButton
                          externalId={externalId}
                          noExternalIdTooltip={t(
                            "calculation-flow.save-to-view",
                          )}
                          size="small"
                        />
                        <TimeSeriesGraphButton
                          externalId={externalId}
                          noExternalIdTooltip={t(
                            "calculation-flow.save-to-view",
                          )}
                          size="small"
                          icon={<LineChartOutlined />}
                        />
                      </Space>
                    </List.Item>
                  )}
                />
                <FormItem>
                  <Button
                    onClick={() => setIsSetpointSelectionModalVisible(true)}
                  >
                    {params.data.externalIds.length > 0
                      ? t("calculation-flow.node-types.change-setpoints")
                      : t("calculation-flow.node-types.select-setpoints")}
                  </Button>
                </FormItem>
                <FormItem label={t("calculation-flow.node-types.priority")}>
                  <Select
                    value={params.data.priority.toString()}
                    style={{ width: 200 }}
                    showAction={["focus", "click"]}
                    onChange={(value) => setPriority(Number(value))}
                    options={[
                      {
                        value: "8",
                        label: t(
                          "calculation-flow.node-types.highest-priority",
                          {
                            value: "8",
                          },
                        ),
                      },
                      { value: "9", label: "9" },
                      { value: "10", label: "10" },
                      { value: "11", label: "11" },
                      { value: "12", label: "12" },
                      { value: "13", label: "13" },
                      { value: "14", label: "14" },
                      { value: "15", label: "15" },
                      {
                        value: "16",
                        label: t(
                          "calculation-flow.node-types.lowest-priority",
                          {
                            value: "16",
                          },
                        ),
                      },
                    ]}
                  />
                </FormItem>
              </Form>
            </NodeInputLabel>
          </NodeInput>
        </Body>
        {showSetpointData && (
          <GraphModal
            timeseriesInfo={showSetpointData}
            setTimeseriesInfo={setShowSetpointData}
            hide={() => setShowSetpointData(null)}
            expanded
            showDocuments={false}
            buildingId={currentBuildingId}
          />
        )}
      </LargeNode>
      <TimeseriesSelectionModal
        hiddenFilters={["building"]}
        initialFilters={{
          category: "setPoint",
        }}
        open={isSetpointSelectionModalVisible}
        onOk={(setpoints) => setSelectedSetpoints(setpoints)}
        onHide={() => setIsSetpointSelectionModalVisible(false)}
        selectedIds={selectedSetpoints.map((setpoint) => setpoint.id)}
        max={20}
      />
    </>
  );
}

export default SetpointsOutputNode;
