import { CloseOutlined } from "@ant-design/icons";
import { MeterSystemSelect } from "@properate/ui";
import { Button, Form, Modal, Space } from "antd";
import { ProperateFile } from "@properate/file-viewer/src/components/types";
import { useTranslationsWithFallback } from "@properate/translations";
import { DEFAULT_GLOBAL_THEME } from "@/theme/graph";
import { UpdateFile } from "@/pages/fileType/types";
import { MessageKey } from "@/utils/types";
import { getSystemTranslationKey } from "@/utils/tfm";

type Props = {
  onHide: () => void;
  id: number;
  files: ProperateFile[];
  onUpdateFiles: (changes: UpdateFile[]) => void;
};
export const AddSystemModal = ({ onHide, id, files, onUpdateFiles }: Props) => {
  const t = useTranslationsWithFallback();

  const existingSystems = [
    ...files.reduce((prev, current) => {
      if (current.metadata?.systems) {
        current.metadata.systems
          .split(",")
          .forEach((system) => prev.add(system));
      }

      return prev;
    }, new Set<string>()),
  ];

  const removeSystem = (system: string) => {
    onUpdateFiles(
      files.map((row) => {
        const id = row.id;
        const systems = (row.metadata?.systems || "")
          .split(",")
          .filter((s) => s !== system && s.length > 0);
        return {
          id,
          update: {
            metadata: {
              set: {
                systems: systems.length > 0 ? systems.join(",") : "",
              },
            },
          },
        };
      }),
    );
    onHide();
  };

  const handleFinish = ({ system }: { system: string }) => {
    onUpdateFiles(
      files.map((row) => {
        const id = row.id;
        const systems = (row.metadata?.systems || "")
          .split(",")
          .filter((s) => s !== system && s.length > 0);
        return {
          id,
          update: {
            metadata: {
              set: {
                systems:
                  systems.length > 0
                    ? [...systems, system || ""].join(",")
                    : system || "",
              },
            },
          },
        };
      }),
    );
    onHide();
  };
  return (
    <Modal title={t("files.add-system")} open onCancel={onHide} footer={null}>
      <Form onFinish={handleFinish}>
        <Space className="flex-wrap">
          {existingSystems.map((system) => (
            <Button
              icon={<CloseOutlined />}
              key={system}
              style={{
                background: DEFAULT_GLOBAL_THEME.color.LIGHT_BLUE,
                color: "white",
              }}
              size="small"
              onClick={() => removeSystem(system)}
            >
              {`${system}: ${t.fallback([
                getSystemTranslationKey(system) as MessageKey,
              ])}`}
            </Button>
          ))}
        </Space>
        <MeterSystemSelect buildingId={id} />
        <Form.Item
          wrapperCol={{ span: 8, offset: 16 }}
          style={{ marginTop: 8 }}
        >
          <Button key="cancel" onClick={onHide} style={{ marginRight: 8 }}>
            {t("files.cancel")}
          </Button>
          <Button htmlType="submit" type="primary">
            {t("files.add")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
