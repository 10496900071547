import axios from "axios";
import { chunk } from "@properate/common";
import { CogniteClient, IdEither } from "@cognite/sdk";
import { cogniteClient } from "@/services/cognite-client";

export const CDF_CONSTANTS = {
  datapoints: {
    limits: {
      query: {
        aggregates: 10000,
        raw: 100000,
      },
      granularity: {
        second: 120,
        minute: 120,
        hour: 100000,
        day: 100000,
      },
    },
  },
};

export const getIcon = async (fileId: number): Promise<string | undefined> => {
  try {
    const response = await axios.get(
      `https://api.cognitedata.com/api/v1/projects/${cogniteClient.project}/files/icon?id=${fileId}`,
      {
        responseType: "arraybuffer",
        headers: cogniteClient.getDefaultRequestHeaders(),
      },
    );

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    return window.URL.createObjectURL(blob);
  } catch (error: any) {
    console.error(error);
    return undefined;
  }
};

export const getFileUrl = async (
  fileId: number,
): Promise<string | undefined> => {
  try {
    const response = await axios.post(
      `https://api.cognitedata.com/api/v1/projects/${cogniteClient.project}/files/downloadlink?extendedExpiration=true`,
      {
        items: [
          {
            id: fileId,
          },
        ],
      },
      {
        headers: cogniteClient.getDefaultRequestHeaders(),
      },
    );

    return response.data.items[0].downloadUrl;
  } catch (error: any) {
    console.error(error);
    return undefined;
  }
};

export const retrieveTimeseriesByChunks = async (
  client: CogniteClient,
  ids: IdEither[],
  params?: Parameters<typeof client.timeseries.retrieve>[1],
  chunkSize = 100,
) => {
  if (ids.length === 0) return Promise.resolve([]);

  return Promise.all(
    chunk(ids, chunkSize).map((chunk) => {
      return client.timeseries.retrieve(chunk, params);
    }),
  );
};

export const retrieveLatestDataPointsByChunks = async (
  client: CogniteClient,
  ids: IdEither[],
  params?: Parameters<typeof client.datapoints.retrieveLatest>[1],
  chunkSize = 100,
) => {
  if (ids.length === 0) return Promise.resolve([]);

  return Promise.all(
    chunk(ids, chunkSize).map((chunk) => {
      return client.datapoints.retrieveLatest(chunk, params);
    }),
  );
};
