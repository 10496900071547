import Icon from "@ant-design/icons";
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useTranslations } from "@properate/translations";

import { FaRunning } from "@react-icons/all-files/fa/FaRunning";
import { WiCloud } from "@react-icons/all-files/wi/WiCloud";
import { WiHumidity } from "@react-icons/all-files/wi/WiHumidity";
import { WiThermometer } from "@react-icons/all-files/wi/WiThermometer";
import { formatSubBuildingFromExternalId } from "@properate/common";
import { CogniteClient } from "@cognite/sdk";
import { ACCENT2 } from "@/utils/ProperateColors";
import { useSchema } from "@/services/schemas";
import { processImage, SchemaView } from "../common/SchemaView/SchemaView";
import { useHelp } from "../../context/HelpContext";
import { Sensor } from "../common/SchemaView/TechnicalSchema/Sensor";
import { useCogniteClient } from "../../context/CogniteClientContext";
import { getAssetsWithLabels } from "../../utils/helpers";
import { useBuildingPageTitle } from "../../hooks/usePageTitle";
import { ReactComponent as SetPointsSvg } from "./icons/tune.svg";

export const FloorPlanView = () => {
  const t = useTranslations();
  useBuildingPageTitle(t("floor-plan.title"));
  const { client } = useCogniteClient();
  const { id, fileId, view }: any = useParams();
  const [buildings, setBuildings] = useState<string[]>([]);
  const { setHelp } = useHelp();
  const [image, setImage] = useState<HTMLElement>();
  const { schema, error, update, archive } = useSchema("floorPlan", fileId);

  if (error) {
    console.error(error);
  }

  useEffect(() => {
    setHelp({
      title: t("floor-plan.help.title"),
      content: t.rich("floor-plan.help.view-page", {
        p: (text) => <p>{text}</p>,
      }),
    });
  }, [setHelp, t]);

  useEffect(() => {
    const get = async (client: CogniteClient) => {
      const assets = [
        ...(await getAssetsWithLabels(client, parseInt(id), ["sub_floor"])),
        ...(await getAssetsWithLabels(client, parseInt(id), ["floor"])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "local_automation",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "comfort_cooling",
        ])),
      ];

      const subBuildings = assets
        ?.reduce((prev, current) => {
          const subBuilding = formatSubBuildingFromExternalId(
            current.externalId!,
          );
          if (subBuilding !== "Common" && !prev.includes(subBuilding)) {
            return [...prev, subBuilding];
          }
          return prev;
        }, [] as string[])
        .sort();
      setBuildings(subBuildings);
    };

    get(client);
  }, [client, id]);

  useEffect(() => {
    if (schema) {
      setImage(processImage(schema.image));
    }
  }, [schema]);

  return (
    <>
      {schema && (
        <SchemaView
          title={t("floor-plan.help.title")}
          id={id}
          fileId={fileId}
          type="floorPlan"
          view={view}
          buildings={buildings}
          schema={schema}
          update={update}
          archive={archive}
          availableSensors={[
            <Sensor
              name={t("floor-plan.sensor-temperature")}
              icon={<WiThermometer />}
              type="floor-sensor-temperature"
              key="floor-sensor-temperature"
            />,
            <Sensor
              name={t("floor-plan.sensor-gas")}
              icon={<WiCloud />}
              type="floor-sensor-gas"
              key="floor-sensor-gas"
            />,
            <Sensor
              name={t("floor-plan.sensor-humidity")}
              icon={<WiHumidity />}
              type="floor-sensor-humidity"
              key="floor-sensor-humidity"
            />,
            <Sensor
              name={t("floor-plan.sensor-motion")}
              icon={<FaRunning />}
              type="floor-sensor-motion"
              key="floor-sensor-motion"
            />,
            <Sensor
              name={t("floor-plan.sensor-writable")}
              icon={<Icon component={SetPointsSvg} />}
              type="set-point-general"
              key="set-point-general"
              color={ACCENT2}
            />,
          ]}
          image={image}
          setImage={setImage}
        />
      )}
    </>
  );
};
