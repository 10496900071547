import { collection, doc, getDoc } from "firebase/firestore";
import { useMemo } from "react";
import { browserFirestore } from "@properate/firebase";
import { useGetFirestoreDocument } from "@properate/ui";
import { useTaskType } from "../contexts/task-type-context";
import type { Task, TaskTemplate } from "../schemas";

export function ensureFields(task: Partial<Task>) {
  return {
    files: [],
    checklists: [],
    usersToRemind: [],
    followers: [],
    components: typeof task.assetId === "number" ? [task.assetId] : [],
    ...task,
  } as Task & { snapshotId: string };
}

export function getTask(taskId: string): Promise<Task | null> {
  return getDoc(doc(collection(browserFirestore, "tasks"), taskId)).then(
    (doc) =>
      doc.exists()
        ? ensureFields({
            ...doc.data(),
            snapshotId: doc.id,
          })
        : null,
  );
}

export function getTaskTemplate(taskId: string): Promise<Task | null> {
  return getDoc(
    doc(collection(browserFirestore, "taskTemplates"), taskId),
  ).then((doc) =>
    doc.exists()
      ? ensureFields({
          ...doc.data(),
          snapshotId: doc.id,
        })
      : null,
  );
}

type GetTaskResult<T extends Task | TaskTemplate | null | void> = {
  data: T;
  isLoading: boolean;
  error: Error | string | null;
};

export function useGetTask(
  taskId: string,
): GetTaskResult<Task | TaskTemplate | null | void>;
export function useGetTask(
  taskId: string,
  serverState: Task,
): GetTaskResult<Task>;
export function useGetTask(
  taskId: string,
  serverState: TaskTemplate,
): GetTaskResult<TaskTemplate>;
export function useGetTask(
  taskId: string,
  serverState?: Task | TaskTemplate,
): GetTaskResult<Task | TaskTemplate | null | void> {
  const taskType = useTaskType();

  const result = useGetFirestoreDocument<Task>(
    useMemo(
      () => doc(collection(browserFirestore, taskType), taskId),
      [taskId, taskType],
    ),
  );

  const data = result.data ?? serverState;

  return {
    data: data ? ensureFields(data) : undefined,
    isLoading: result.isLoading,
    error: result.error,
  };
}
