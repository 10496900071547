import { useTranslations } from "@properate/translations";
import { Element } from "@sasza/react-panzoom";
import { Button, Popconfirm, Popover, Radio, Select, Spin } from "antd";
import { ReactNode, useState } from "react";
import { Resizable } from "react-resizable";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useSWRMutation from "swr/mutation";
import { usePanZoomAreaInteraction } from "./PanZoomAreaInteractionContext";

export type PanZoomAreaLinkType = {
  id: string;
  target: string;
  position: {
    x: number;
    y: number;
  };
  size: {
    width: number;
    height: number;
  };
};

const Display = styled.div<PanZoomAreaLinkType["size"] & { isValid: boolean }>`
  background: ${(props) =>
    props.isValid ? "rgba(0, 0, 255, 0.15)" : "rgba(255, 0, 0, 0.15)"};
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

type Props = {
  link: PanZoomAreaLinkType;
} & (
  | { isDraft: true }
  | {
      isDraft: false;
      isEditing: boolean;
      getLinkHref: (link: PanZoomAreaLinkType) => string;
      targetOptions: Array<{ label: string; value: string }>;
      onChange: (link: PanZoomAreaLinkType) => Promise<void>;
      onDelete: (id: string) => Promise<void>;
    }
);

export function PanZoomAreaLink(props: Props) {
  const { isMoving, setIsMoving, inResizingMode } = usePanZoomAreaInteraction();

  const [size, setSize] = useState({
    width: props.link.size.width,
    height: props.link.size.height,
  });

  if (props.isDraft) {
    return (
      <Element
        id="draft"
        x={props.link.position.x}
        y={props.link.position.y}
        disabled
      >
        <Display {...size} isValid={false}>
          <Spin />
        </Display>
      </Element>
    );
  }

  const onChange = props.onChange;
  const isValid = props.link.target !== "";

  function handleResizeStop(params: { width: number; height: number }) {
    onChange({
      ...props.link,
      size: {
        width: params.width,
        height: params.height,
      },
    });
  }

  function handleMove(params: { x: number; y: number }) {
    setIsMoving(false);
    onChange({
      ...props.link,
      position: {
        x: params.x,
        y: params.y,
      },
    });
  }

  return (
    <Element
      id={props.link.id}
      x={props.link.position.x}
      y={props.link.position.y}
      disabled={inResizingMode}
      disabledMove={!props.isEditing}
      onMouseUp={handleMove}
    >
      {isMoving ? (
        <Display {...size} isValid={isValid} />
      ) : inResizingMode ? (
        <Resizable
          width={size.width}
          height={size.height}
          onResize={(event, data) => setSize(data.size)}
          onResizeStop={(event, data) => handleResizeStop(data.size)}
        >
          <PanZoomAreaLinkEditor
            link={props.link}
            isEditing={props.isEditing}
            onChange={props.onChange}
            onDelete={props.onDelete}
            targetOptions={props.targetOptions}
          >
            <Display {...size} isValid={isValid} />
          </PanZoomAreaLinkEditor>
        </Resizable>
      ) : props.isEditing ? (
        <PanZoomAreaLinkEditor
          link={props.link}
          isEditing={props.isEditing}
          onChange={props.onChange}
          onDelete={props.onDelete}
          targetOptions={props.targetOptions}
        >
          <Display {...size} isValid={isValid} />
        </PanZoomAreaLinkEditor>
      ) : isValid ? (
        <Link to={props.getLinkHref(props.link)}>
          <Display {...size} isValid={isValid} />
        </Link>
      ) : null}
    </Element>
  );
}

type PanZoomAreaLinkEditorProps = {
  link: PanZoomAreaLinkType;
  isEditing: boolean;
  targetOptions: Array<{ label: string; value: string }>;
  onChange: (link: PanZoomAreaLinkType) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  children: ReactNode;
};

function PanZoomAreaLinkEditor(props: PanZoomAreaLinkEditorProps) {
  const t = useTranslations();
  const { inResizingMode, setInResizingMode } = usePanZoomAreaInteraction();

  const updateMutation = useSWRMutation(
    ["pan-zoom-area-link-update", props.link.id],
    async (key, { arg }: { arg: PanZoomAreaLinkType }) => {
      await props.onChange(arg);
    },
  );

  const deleteMutation = useSWRMutation(
    ["pan-zoom-area-link-delete", props.link.id],
    async () => {
      await props.onDelete(props.link.id);
    },
  );

  if (!props.isEditing) {
    return props.children;
  }

  return (
    <Popover
      trigger="click"
      content={
        <div className="flex flex-col gap-4 w-96">
          <div className="flex flex-col gap-1">
            <div>{t("pan-zoom-area-link.field-target")}:</div>
            <Select
              className="flex-1"
              showSearch
              options={props.targetOptions}
              loading={updateMutation.isMutating}
              defaultValue={props.link.target}
              onChange={(value) =>
                updateMutation.trigger({
                  ...props.link,
                  target: value,
                })
              }
            />
            {!props.link.target && (
              <div className="text-red-500 text-xs">
                {t("pan-zoom-area-link.field-target-required")}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <div>{t("pan-zoom-area-link.field-editing-mode")}:</div>
            <Radio.Group
              value={inResizingMode ? "resize" : "move"}
              onChange={() => setInResizingMode(!inResizingMode)}
            >
              <Radio.Button value="move">
                {t("pan-zoom-area-link.field-editing-mode-move")}
              </Radio.Button>
              <Radio.Button value="resize">
                {t("pan-zoom-area-link.field-editing-mode-resize")}
              </Radio.Button>
            </Radio.Group>
            <div className="text-muted-foreground text-xs">
              {t("pan-zoom-area-link.field-editing-mode-hint")}
            </div>
          </div>
          <div className="flex justify-end">
            <Popconfirm
              title={t("ui.confirm")}
              onConfirm={() => deleteMutation.trigger()}
            >
              <Button danger disabled={deleteMutation.isMutating}>
                {t("ui.delete")}
              </Button>
            </Popconfirm>
          </div>
        </div>
      }
    >
      {props.children}
    </Popover>
  );
}
