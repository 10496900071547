import { createContext, useContext, useEffect } from "react";

type Context = {
  isMoving: boolean;
  setIsMoving: (isMoving: boolean) => void;
  inResizingMode: boolean;
  setInResizingMode: (inResizingMode: boolean) => void;
};

const context = createContext<Context>({
  isMoving: false,
  setIsMoving: () => undefined,
  inResizingMode: false,
  setInResizingMode: () => undefined,
});

type Props = {
  isEditing: boolean;
  isMoving: boolean;
  setIsMoving: (isMoving: boolean) => void;
  inResizingMode: boolean;
  setInResizingMode: (inResizingMode: boolean) => void;
  children: React.ReactNode;
};

export function PanZoomAreaInteractionContext({
  isEditing,
  isMoving,
  setIsMoving,
  inResizingMode,
  setInResizingMode,
  children,
}: Props) {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setInResizingMode(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setInResizingMode(false);
      }
    };

    if (isEditing) {
      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("keyup", handleKeyUp);
      };
    }
  }, [setInResizingMode, isEditing]);

  return (
    <context.Provider
      value={{
        isMoving,
        setIsMoving,
        inResizingMode,
        setInResizingMode,
      }}
    >
      {children}
    </context.Provider>
  );
}

export function usePanZoomAreaInteraction() {
  return useContext(context);
}
