import { useNavigate } from "react-router-dom";
import { SettingOutlined } from "@ant-design/icons/lib";
import { useTranslations } from "@properate/translations";
import { Button, TableColumnsType } from "antd";
import { arrayMoveImmutable } from "array-move";
import { useUser } from "@properate/auth";
import { sortString } from "@properate/ui";
import { SchemaTableProps } from "@/components/TechnicalSchema/types";
import {
  deleteTimeseries,
  getCurrentSensors,
  getSensors,
  setTimeseries,
} from "@/components/TechnicalSchema/utils";
import ShowImage from "@/pages/common/SchemaList/ShowImage";
import {
  KpiComponentContainer,
  TitleContainer,
  RelativeContainer,
  KpiCommonWrapperContainer,
} from "@/components/TechnicalSchema/elements";
import { SearchHighlighter } from "@/components/SearchHighlighter";
import { TableWithoutDefaultSort } from "../TableWithoutDefaultSort/TableWithoutDefaultSort";
import { SortableKPIList } from "./SortableKPIList";

export const SchemaExpandViewTable = ({
  data,
  type,
  setShowSelectKpisModal,
  selectedBuilding,
  title,
  onChangeSelectedSensors,
  height,
  search,
}: SchemaTableProps) => {
  const navigate = useNavigate();
  const user = useUser();
  const t = useTranslations();

  function handleSortEnd(
    snapshotId: string,
    oldIndex: number,
    newIndex: number,
  ) {
    const { selectedSensors } = data.find(
      (schema: any) => schema.snapshotId === snapshotId,
    );

    onChangeSelectedSensors(
      snapshotId,
      arrayMoveImmutable(selectedSensors, oldIndex, newIndex),
    );
  }

  const columns: TableColumnsType<any> = [
    {
      title: t("floor-plan.table-column-title.building"),
      width: 90,
      key: "subBuilding",
      sorter: (
        { subBuilding: subBuildingOne },
        { subBuilding: subBuildingTwo },
      ) => sortString(subBuildingOne, subBuildingTwo),
      render: (_, { subBuilding = "" }) => {
        return (
          <TitleContainer>
            <SearchHighlighter search={search} text={subBuilding} />
          </TitleContainer>
        );
      },
    },
    {
      title: t("floor-plan.table-column-title.system"),
      width: 90,
      key: "system",
      defaultSortOrder: "ascend",
      sorter: ({ system: systemOne }, { system: systemTwo }) =>
        sortString(systemOne, systemTwo),
      render: (_, { system = "" }) => {
        return (
          <TitleContainer>
            <SearchHighlighter search={search} text={system} />
          </TitleContainer>
        );
      },
    },
    {
      title: t("floor-plan.table-column-title.key-figures-and-alarms"),
      key: "kpi",
      width: 300,
      render: (_, { snapshotId, sensors, selectedSensors, name }) => {
        const sensorList = getSensors(sensors);
        const currentSensors = getCurrentSensors(selectedSensors, sensors);

        return (
          <RelativeContainer>
            <TitleContainer title={name || title}>
              <SearchHighlighter search={search} text={name || title} />
            </TitleContainer>
            <span className="settings">
              {sensorList && sensorList.length > 0 && (
                <Button
                  disabled={user.isViewer}
                  type="link"
                  icon={<SettingOutlined />}
                  onClick={() => {
                    setShowSelectKpisModal(snapshotId);
                  }}
                />
              )}
            </span>
            <KpiComponentContainer>
              <KpiCommonWrapperContainer>
                <SortableKPIList
                  sensors={currentSensors.filter((sensor) => sensor.sensor.id)}
                  onChangeTimeseriesInfo={async (sensor, ts) =>
                    await setTimeseries(
                      snapshotId,
                      sensor.id,
                      sensors[sensor.id],
                      ts,
                      type,
                    )
                  }
                  onDeleteTimeseries={async (sensor) =>
                    await deleteTimeseries(
                      snapshotId,
                      sensors,
                      sensor.id,
                      sensor.sensor.id,
                      selectedSensors || [],
                      type,
                    )
                  }
                  onSortEnd={({ oldIndex, newIndex }) =>
                    handleSortEnd(snapshotId, oldIndex, newIndex)
                  }
                  axis="xy"
                />
              </KpiCommonWrapperContainer>
              <KpiCommonWrapperContainer>
                <SortableKPIList
                  sensors={sensorList
                    .filter((sensor) => sensor.sensor.id)
                    .filter(
                      (sensor) =>
                        !currentSensors.some(
                          (s: any) => sensor.sensor.id === s.sensor.id,
                        ),
                    )}
                  onChangeTimeseriesInfo={async (sensor, ts) =>
                    await setTimeseries(
                      snapshotId,
                      sensor.id,
                      sensors[sensor.id],
                      ts,
                      type,
                    )
                  }
                  onDeleteTimeseries={async (sensor) =>
                    await deleteTimeseries(
                      snapshotId,
                      sensors,
                      sensor.id,
                      sensor.sensor.id,
                      selectedSensors || [],
                      type,
                    )
                  }
                  onSortEnd={({ oldIndex, newIndex }) =>
                    handleSortEnd(snapshotId, oldIndex, newIndex)
                  }
                  axis="xy"
                  onlyAlarm
                />
              </KpiCommonWrapperContainer>
            </KpiComponentContainer>
          </RelativeContainer>
        );
      },
    },
    {
      title: title,
      width: 300,
      key: "floorPlan",
      sorter: ({ name: nameOne }, { name: nameTwo }) =>
        sortString(nameOne, nameTwo),
      render: (
        _,
        { rootAssetId, snapshotId, image, sensors, cogniteFileId },
      ) => (
        <div
          style={{ position: "relative", cursor: "pointer" }}
          onClick={() => {
            navigate(`/asset/${rootAssetId}/${type}/${snapshotId}/view`);
          }}
        >
          <ShowImage
            cogniteFileId={cogniteFileId}
            image={image}
            sensors={sensors}
          />
        </div>
      ),
    },
  ];

  return (
    data && (
      <TableWithoutDefaultSort
        columns={columns}
        pagination={false}
        dataSource={data.filter(
          ({
            subBuilding,
            system,
            name,
          }: {
            subBuilding: string;
            system?: string;
            name: string;
          }) =>
            (selectedBuilding === "all" || subBuilding === selectedBuilding) &&
            (!search ||
              subBuilding?.toLowerCase().includes(search.toLowerCase()) ||
              (system && system.toLowerCase().includes(search.toLowerCase())) ||
              name?.toLowerCase().includes(search.toLowerCase())),
        )}
        scroll={{
          y: height,
        }}
        rowKey="snapshotId"
      />
    )
  );
};
