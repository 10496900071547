import { useRouteError } from "react-router-dom";
import { Result } from "antd";
import { MessageKey, useTranslations } from "@properate/translations";

class BadDataError extends Error {
  title: MessageKey = "pages.error.default.title";
  subTitle: MessageKey = "pages.error.default.description";
}

export class NoMainMeterError extends BadDataError {
  title: MessageKey = "energy.error.no-main-meter.title";
  subTitle: MessageKey = "energy.error.no-main-meter.description";
}

export function BadDataErrorBoundary() {
  const error = useRouteError();
  const t = useTranslations();

  if (error instanceof BadDataError) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Result
          status="error"
          title={t(error.title)}
          subTitle={t(error.subTitle)}
        />
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <Result status="500" />
    </div>
  );
}
