import {
  AlarmRuleTypeName,
  convertHoursToMS,
  MissingDataAlarmRule,
  PartialAlarmRule,
} from "@properate/common";
import { useTranslations } from "@properate/translations";
import { Select } from "antd";
import { RichTextFormat } from "@properate/ui";
import {
  AlarmDeviationTable,
  DeviationTableColumn,
} from "@/pages/alarms/details/components/AlarmDeviationTable";
import {
  FormContextItem,
  useFormValue,
} from "@/pages/alarms/details/FormContext";
import { LabelDirection } from "@/pages/alarms/details/FormContext/FormContextItem";
import { AlarmGraphFormFields } from "@/pages/alarms/details/components/AlarmTimeseriesGraph";
import { getSelectTimeseriesEntries } from "../components/TimeseriesSelector/SelectTimeseries";
import { TimeseriesSelector } from "../components/TimeseriesSelector";
import { getSubmitValueEntry } from "../FormContext/utils";
import { tKey } from "./common/utils";
import {
  TimeseriesThresholdAlarmMetaFields,
  TimeseriesThresholdFormFields,
  TimeseriesThresholdTypeSpecificSummary,
} from "./TimeseriesThreshold";
import { AlarmRuleType, TypeDescriptionProps } from "./index";

export enum MissingDataFormFields {
  Period = "Period",
}

function MissingDataMessage({
  disabledFormatters,
  formatOverrides,
  hasDuration,
}: TypeDescriptionProps) {
  return (
    <RichTextFormat
      message={
        hasDuration
          ? "alarm-details.alarm-types.timeseries-threshold.missing-data.type-description-with-duration"
          : "alarm-details.alarm-types.timeseries-threshold.missing-data.type-description"
      }
      disabledFormatters={disabledFormatters}
      formatOverrides={formatOverrides}
    />
  );
}

export const MissingDataDefinition: AlarmRuleType = {
  name: AlarmRuleTypeName.MissingData,
  TypeDescription: MissingDataMessage,
  labelTranslationKey: tKey("timeseries-threshold.missing-data.type-label"),
  getFormFields: (alarmRule) => {
    return {
      [TimeseriesThresholdFormFields.Timeseries]: {
        defaultValue:
          alarmRule?.condition?.type_specific?.base_timeseries_id ?? undefined,
      },
      [MissingDataFormFields.Period]: {
        defaultValue:
          (alarmRule as MissingDataAlarmRule)?.condition?.type_specific
            ?.period_ms ?? convertHoursToMS(1),
      },
      ...getSelectTimeseriesEntries(),
    };
  },
  getAlarmRuleFields: ({ entries }): PartialAlarmRule => {
    return {
      condition: {
        type_specific: {
          base_timeseries_id: getSubmitValueEntry<number>(
            entries,
            TimeseriesThresholdFormFields.Timeseries,
          ),
          period_ms: getSubmitValueEntry<number>(
            entries,
            MissingDataFormFields.Period,
          ),
        },
      },
    };
  },
  formComponent: <MissingDataPoints />,
  summaryContents: {
    generalAlarmMetaFields: <TimeseriesThresholdAlarmMetaFields />,
    typeSpecificSummary: <TimeseriesThresholdTypeSpecificSummary />,
  },
  getDeviationsTable: (props) => (
    <AlarmDeviationTable
      columns={[
        DeviationTableColumn.Timeframe,
        DeviationTableColumn.LastDatapoint,
        DeviationTableColumn.Delay,
      ]}
      timeseriesIdFormField={TimeseriesThresholdFormFields.Timeseries}
      {...props}
    />
  ),
};

function MissingDataTimeSelect() {
  const HOUR_MS = 60 * 60 * 1000;
  const t = useTranslations();
  const missingDataHours = [1, 2, 3, 4, 5, 6, 12, 24, 48];
  const missingDataOptions = missingDataHours.map((value: number) => ({
    label: t(tKey(`timeseries-threshold.missing-data.delay-options.${value}`)),
    value: HOUR_MS * value,
  }));

  return (
    <FormContextItem
      labelKey={tKey(`timeseries-threshold.missing-data.delay-label`)}
      id={MissingDataFormFields.Period}
      labelDirection={LabelDirection.Vertical}
      antdInput
      inputProps={{
        options: missingDataOptions,
      }}
    >
      <Select />
    </FormContextItem>
  );
}

function MissingDataPoints() {
  const t = useTranslations();
  const label = t(tKey(`timeseries-threshold.select-timeseries`));
  const [_showGraph, setShowGraph] = useFormValue<boolean>(
    AlarmGraphFormFields.GraphVisible,
  );
  return (
    <TimeseriesSelector
      name={TimeseriesThresholdFormFields.Timeseries}
      selectLabel={label}
      displayLabel={label}
      onOpen={() => setShowGraph(false)}
      onClose={() => setShowGraph(true)}
    >
      <MissingDataTimeSelect />
    </TimeseriesSelector>
  );
}
