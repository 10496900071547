import { Fragment, useEffect, useState } from "react";
import { Position } from "@sasza/react-panzoom/types/types";
import { Element } from "@sasza/react-panzoom";
import { cn } from "@properate/ui";
import { Popover } from "antd";
import { useTranslations } from "@properate/translations";
import styled from "styled-components";
import {
  useFloorPlan,
  useFloorPlanEditMode,
  useFloorPlanIsMovingPin,
} from "./FloorPlanContext";
import {
  MoveHandler,
  useFloorPlanMapApi,
  useFloorPlanMapMoveHandlers,
} from "./FloorPlanMapContext";
import { Pin } from "./types";
import { FloorPlanMapPin } from "./FloorPlanMapPin";
import { useFloorPlanUpdate } from "./hooks/useFloorPlanUpdate";
import {
  FloorPlanPinPopoverProvider,
  useFloorPlanPinPopover,
} from "./FloorPlanMapPinPopoverContext";
import {
  ValueNameViewTimeseriesDescription,
  ValueNameViewRoomDescription,
  AlarmIcon,
} from "./FloorPlanMapPinValue";
import { FloorPlanPinProvider } from "./FloorPlanMapPinContext";

type Cluster = {
  id: string;
  position: Position;
  pinIds: string[];
};

export function FloorPlanMapPinCluster() {
  const floorPlan = useFloorPlan();
  const mapApi = useFloorPlanMapApi();
  const moveHandlers = useFloorPlanMapMoveHandlers();
  const [clusters, setClusters] = useState<Cluster[]>([]);

  useEffect(() => {
    let lastZoom: number | null = null;

    const handler: MoveHandler = ({ zoom }) => {
      if (lastZoom === zoom) {
        return;
      }

      // If we reach the max zoom level, we disable clustering and show all pins.
      if (zoom === 5) {
        return setClusters(
          floorPlan.pins.map((pin) => ({
            id: pin.id,
            position: pin.position,
            pinIds: [pin.id],
          })),
        );
      }

      lastZoom = zoom;

      const clusterRadius = 50 / zoom;
      const groupedPins: Pin[][] = [];

      floorPlan.pins.forEach((pin) => {
        let addedToCluster = false;

        for (const cluster of groupedPins) {
          const clusterCenter = cluster[0];

          const distance = Math.sqrt(
            Math.pow(pin.position.x - clusterCenter.position.x, 2) +
              Math.pow(pin.position.y - clusterCenter.position.y, 2),
          );

          if (distance <= clusterRadius) {
            cluster.push(pin);
            addedToCluster = true;
            break;
          }
        }

        if (!addedToCluster) {
          groupedPins.push([pin]);
        }
      });

      const clusters: Cluster[] = groupedPins.map((pins) => {
        const pinIds = pins.map((pin) => pin.id);

        const avgPosition = {
          x: pins.reduce((sum, pin) => sum + pin.position.x, 0) / pins.length,
          y: pins.reduce((sum, pin) => sum + pin.position.y, 0) / pins.length,
        };

        return {
          id: pinIds.join(","),
          position: avgPosition,
          pinIds,
        };
      });

      setClusters(clusters);
    };

    if (mapApi) {
      handler({
        position: mapApi.getPosition(),
        zoom: mapApi.getZoom(),
      });
    }

    moveHandlers.add(handler);

    return () => {
      moveHandlers.delete(handler);
    };
  }, [moveHandlers, setClusters, mapApi, floorPlan.pins]);

  // We want to make the pins readable no matter the zoom level.
  // We do this by counteracting the map zoom level with pin scale.
  const scale = "calc(1 / var(--zoom))";

  return (
    <>
      {clusters.map((cluster) => {
        if (cluster.pinIds.length === 1) {
          return cluster.pinIds.map((pinId) => {
            const pin = floorPlan.pins.find((pin) => {
              return pin.id === pinId;
            });

            if (pin) {
              return (
                <PanZoomPin key={pin.id} pin={pin}>
                  <div style={{ scale }}>
                    <FloorPlanMapPin key={pin.id} pin={pin} />
                  </div>
                </PanZoomPin>
              );
            }

            return null;
          });
        }

        return (
          <Element
            key={cluster.id}
            id={cluster.id}
            x={cluster.position.x}
            y={cluster.position.y}
            disabled
          >
            <div style={{ scale }}>
              <FloorPlanPinPopoverProvider>
                <FloorPlanClusterPin cluster={cluster} />
              </FloorPlanPinPopoverProvider>
            </div>
          </Element>
        );
      })}
    </>
  );
}

type PanZoomPinProps = {
  pin: Pin;
  children: React.ReactNode;
};

function PanZoomPin(props: PanZoomPinProps) {
  const [isEditing] = useFloorPlanEditMode();
  const updateFloorPlan = useFloorPlanUpdate();
  const [_, setIsMovingPin] = useFloorPlanIsMovingPin();

  function handleMouseUp(pos: Position & Object) {
    setIsMovingPin(false);

    const hasMoved =
      pos.x !== props.pin.position.x || pos.y !== props.pin.position.y;

    if (hasMoved) {
      updateFloorPlan.trigger({
        pins: {
          remove: props.pin,
          insert: [
            {
              ...props.pin,
              position: {
                x: pos.x,
                y: pos.y,
              },
            },
          ],
        },
      });
    }
  }

  return (
    <Element
      id={props.pin.id}
      x={props.pin.position.x}
      y={props.pin.position.y}
      onMouseUp={handleMouseUp}
      disabledMove={!isEditing}
    >
      {props.children}
    </Element>
  );
}

const ClusterAlarmPositioner = styled.div`
  position: relative;
  height: 2rem;
  box-sizing: border-box;

  &:has([data-level]) {
    padding-left: 2.25rem;
  }

  [data-level] {
    position: absolute;
    top: 3px;
    left: 0.25rem;
    border-radius: 0.25rem;
  }

  [data-level="warning"] {
    z-index: 100;
  }

  [data-level="error"] {
    z-index: 200;
  }
`;

type FloorPlanClusterPinProps = {
  cluster: Cluster;
};

function FloorPlanClusterPin(props: FloorPlanClusterPinProps) {
  const t = useTranslations();
  const floorPlan = useFloorPlan();
  const [isPopoverOpen, setPopoverOpen] = useFloorPlanPinPopover();
  const count = props.cluster.pinIds.length;

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={setPopoverOpen}
      trigger="hover"
      title={t("floor-plan-v2.cluster-popover-title", { count })}
      content={
        <div className="flex flex-col gap-y-4">
          {props.cluster.pinIds.map((pinId) => {
            const pin = floorPlan.pins.find((pin) => pin.id === pinId);

            if (pin) {
              return (
                <Fragment key={pin.id}>
                  <div>
                    <FloorPlanPinProvider pin={pin}>
                      {pin.type === "room" ? (
                        <ValueNameViewRoomDescription roomId={pin.roomId} />
                      ) : pin.type === "label" ? (
                        <div>{pin.label}</div>
                      ) : (
                        <ValueNameViewTimeseriesDescription />
                      )}
                    </FloorPlanPinProvider>
                  </div>
                </Fragment>
              );
            }
          })}
        </div>
      }
    >
      <ClusterAlarmPositioner
        className={cn(
          "flex items-center gap-2 bg-muted text-muted-foreground rounded hover:bg-card",
          "border border-solid border-black",
          "cursor-pointer",
          "px-3",
        )}
      >
        {props.cluster.pinIds.map((pinId) => {
          const pin = floorPlan.pins.find((pin) => pin.id === pinId);

          if (pin && pin.type !== "room" && pin.type !== "label") {
            return (
              <FloorPlanPinProvider key={pin.id} pin={pin}>
                <AlarmIcon />
              </FloorPlanPinProvider>
            );
          }
        })}
        {count}
      </ClusterAlarmPositioner>
    </Popover>
  );
}
