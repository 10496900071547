import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Timeseries } from "@cognite/sdk";
import {
  AutoSizedSimpleGraph,
  SimpleGraphContextProvider,
} from "@/features/analysis";
import {
  useAlarmDetailsTimeSpan,
  useAlarmGraphLegendData,
  useGraphHighlightsForEvents,
  useTimeseries,
} from "../../hooks";
import { useFormValue } from "../../FormContext/hooks";
import { AlarmTimeseriesGraphLayout } from "./AlarmTimeseriesGraphLayout";
import { AlarmTimeseriesNavigator } from "./AlarmTimeseriesNavigator";
import AlarmTimeseriesEmpty from "./AlarmTimeseriesEmpty";
import { getColor } from "./utils";

function SingleTimeseriesGraphImpl({ tsName }: { tsName: string }) {
  const [tsId] = useFormValue<number>(tsName);
  const themeContext = useContext(ThemeContext);
  const { timeseries } = useTimeseries({
    timeseriesId: tsId,
  });
  const highlights = useGraphHighlightsForEvents();
  const timeSpan = useAlarmDetailsTimeSpan();
  const color = getColor(0, themeContext);

  const legendData = useAlarmGraphLegendData({
    timeseriesList: [timeseries],
    timeseriesSettings: {
      [tsId]: {
        color,
        labelKey: "ui.value",
      },
    },
  });

  function renderGraph() {
    return (
      <AutoSizedSimpleGraph
        interactive
        timeSpan={timeSpan}
        highlights={highlights}
        settingsTimeseriesList={[
          {
            id: tsId,
            hidden: false,
            color,
            unitSelected: timeseries?.unit ?? "",
          },
        ]}
        legendData={legendData}
      />
    );
  }

  function renderNavigator() {
    const tslist = [
      {
        timeseries: timeseries as Timeseries,
        color,
      },
    ];
    return <AlarmTimeseriesNavigator timeseriesWithDataPointsList={tslist} />;
  }

  if (!tsId || !timeseries) {
    return <AlarmTimeseriesEmpty />;
  }

  return (
    <AlarmTimeseriesGraphLayout
      graph={renderGraph()}
      navigator={renderNavigator()}
    />
  );
}

export default function SingleTimeseriesGraph({ tsName }: { tsName: string }) {
  return (
    <SimpleGraphContextProvider>
      <SingleTimeseriesGraphImpl tsName={tsName} />
    </SimpleGraphContextProvider>
  );
}
