import useMutation from "swr/mutation";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { MessageKey, useTranslations } from "@properate/translations";
import { AlertRule } from "@properate/common";
import useSWR from "swr";
import { useMemo } from "react";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import {
  createAlertConfiguration,
  deleteAlertConfiguration,
  listGroup,
  updateAlertConfiguration,
} from "@/eepApi";
import { useHandleApiError } from "@/utils/api";

export const TIMEOUT_SELECT_OPTIONS: {
  value: number;
  translationKey: MessageKey;
}[] = [
  {
    value: 5,
    translationKey: "alert-rule.timeout-options.5-minutes",
  },
  {
    value: 10,
    translationKey: "alert-rule.timeout-options.10-minutes",
  },
  {
    value: 15,
    translationKey: "alert-rule.timeout-options.15-minutes",
  },
  {
    value: 20,
    translationKey: "alert-rule.timeout-options.20-minutes",
  },
  {
    value: 25,
    translationKey: "alert-rule.timeout-options.25-minutes",
  },
  {
    value: 30,
    translationKey: "alert-rule.timeout-options.30-minutes",
  },
  {
    value: 60,
    translationKey: "alert-rule.timeout-options.1-hour",
  },
  {
    value: 120,
    translationKey: "alert-rule.timeout-options.2-hours",
  },
];

export type FormValues = {
  building_external_id: string;
  name: string;
  muted: boolean;
  type: "select" | "query";
  alarm_rule_selector: NonNullable<AlertRule["alarm_rule_selector"]>;
  alarm_rule_ids: string[];
  group_rules: NonNullable<AlertRule["group_rules"]>;
  group_rules_exceptions: NonNullable<AlertRule["group_rules_exceptions"]>;
  fallback_group_rule?: AlertRule["fallback_group_rule"];
};

export const mapValues = (values: FormValues): AlertRule => {
  const hasFallbackGroupRule =
    !!values.fallback_group_rule?.group_id &&
    (values.fallback_group_rule.notify_by_priority.notify_all ||
      values.fallback_group_rule.notify_by_priority.timeout !== undefined);
  return {
    ...values,
    fallback_group_rule: hasFallbackGroupRule
      ? values.fallback_group_rule
      : null,
  };
};

export function useUpdateAlertRule() {
  const t = useTranslations();

  return useMutation(
    ["alerts", "rules", "update"],
    async (_key, params: { arg: { rule: AlertRule; id: string } }) => {
      await updateAlertConfiguration(params.arg.id, params.arg.rule);
    },
    {
      onSuccess() {
        message.success(t("alert-rule.status-update-success"));
      },
      onError(error) {
        message.error(t("alert-rule.status-update-error"));
        console.error(error);
      },
    },
  );
}

export function useCreateAlertRule() {
  const t = useTranslations();
  const building = useCurrentBuilding();
  const navigate = useNavigate();

  return useMutation(
    ["alerts", "rules", "create"],
    async (_key, params: { arg: Omit<AlertRule, "building_external_id"> }) => {
      return await createAlertConfiguration({
        ...params.arg,
        building_external_id: building.externalId!,
      });
    },
    {
      onSuccess(config) {
        message.success(t("alert-rule.status-create-success"));
        navigate(`/asset/${building.id}/alertConfiguration/${config.id}`);
      },
      onError(error) {
        message.error(t("alert-rule.status-create-error"));
        console.error(error);
      },
    },
  );
}

export function useDeleteAlertRule() {
  const t = useTranslations();
  const buildingId = useCurrentBuildingId();
  const navigate = useNavigate();

  return useMutation(
    ["alerts", "rules", "delete"],
    async (_key, params: { arg: { id: string } }) => {
      await deleteAlertConfiguration(params.arg.id);
    },
    {
      onSuccess() {
        message.success(t("alert-rule.status-delete-success"));
        navigate(`/asset/${buildingId}/alertConfiguration`);
      },
      onError(error) {
        message.error(t("alert-rule.status-delete-error"));
        console.error(error);
      },
    },
  );
}

export const useGroups = () => {
  const building = useCurrentBuilding();
  const handleAPIError = useHandleApiError();

  const { data, error, isLoading } = useSWR(
    ["ruleAlertGroups", building.externalId],
    () =>
      listGroup({
        filter: {
          building_external_id: building.externalId!,
        },
      }),
    {
      keepPreviousData: true,
    },
  );

  if (error !== undefined) {
    handleAPIError(error);
  }

  return useMemo(
    () => ({
      data: data?.items || [],
      isLoading,
    }),
    [data?.items, isLoading],
  );
};
