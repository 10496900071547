import useSWR from "swr";
import { useEffect, useState } from "react";

import { Button, Select, Space, Tooltip, Switch } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { CogniteClient } from "@cognite/sdk";
import {
  ClockCircleOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { Step } from "react-joyride";
import { formatMeasurement, Measurement } from "@properate/common";
import dayjs from "@properate/dayjs";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { sortString, ToggleSidebarButton } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { roomsIndex } from "@/eepApi";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";
import { CompactContent } from "@/components/CompactContent";
import { getLatestDataPoints } from "@/utils/helpers";
import { DEFAULT_GLOBAL_THEME } from "@/theme/graph";
import { useOnboarding } from "@/utils/onboarding";
import { SearchInput } from "@/components/SearchIndex";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import RoomInfo from "@/pages/common/RoomInfo";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useWindowSize } from "@/hooks/useWindowSize";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import {
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { IndoorClimateTableNotesColumn } from "@/features/indoorClimate";
import GraphModal from "../common/SchemaGraph";
import { JoyrideWrapper } from "../../components/JoyrideWrapper/JoyrideWrapper";
import { useHelp } from "../../context/HelpContext";
import { useCogniteClient } from "../../context/CogniteClientContext";

type SensorInfo = {
  max?: number;
  min?: number;
  mean?: number;
  value?: number;
};

const VALUE_COL_WIDTH = 100;

const WarningText = styled.div`
  display: flex;
`;
type Sensors =
  | "temperature"
  | "humidity_sensor"
  | "motion"
  | "VOC"
  | "co2"
  | "radon";
type RoomInfoType = {
  id: string;
  externalId: string;
  floor: string;
  name: string;
  assetExternalId?: string;
  subBuilding?: string;
  specie: string;
  description: string;
  temperature?: SensorInfo;
  humidity_sensor?: SensorInfo;
  motion?: SensorInfo;
  VOC?: SensorInfo;
  co2?: SensorInfo;
  radon?: SensorInfo;
};

const ESTIMATED_PAGE_HEADER_HEIGHT = 64;
const ESTIMATED_TABLE_HEADER_HEIGHT = 45;

const getIds = (sensorInfo: any) => {
  const array: number[] = [];
  if (sensorInfo) {
    [sensorInfo.max, sensorInfo.min, sensorInfo.mean, sensorInfo.value].forEach(
      (val) => {
        if (val) {
          array.push(val);
        }
      },
    );
  }
  return array;
};

const getSubBuildingFromExternalId = (externalId: string) => {
  const sb = /\+(.*?)=/.exec(externalId);
  return sb![1];
};

const getSubBuildings = async (
  client: CogniteClient,
  id: number,
): Promise<string[]> => {
  const subBuildings = await client.assets
    .list({
      filter: {
        assetSubtreeIds: [{ id }],
        labels: { containsAll: [{ externalId: "building" }] },
      },
    })
    .autoPagingToArray({ limit: Infinity });

  return (
    subBuildings
      .map((asset) => {
        return getSubBuildingFromExternalId(asset.externalId!);
      })
      .sort((a, b) => a.localeCompare(b)) || []
  );
};

const getFloors = async (
  client: CogniteClient,
  id: number,
  subBuilding: string,
): Promise<string[]> => {
  const floors = await client.assets
    .list({
      filter: {
        assetSubtreeIds: [{ id }],
        labels: {
          containsAny: [{ externalId: "sub_floor" }, { externalId: "floor" }],
        },
      },
    })
    .autoPagingToArray({ limit: Infinity });

  return (
    [
      ...new Set(
        floors
          .filter(
            (asset) =>
              subBuilding === "all" ||
              getSubBuildingFromExternalId(asset.externalId!) === subBuilding,
          )
          .map((floor) => {
            return floor.metadata?.floor || floor.name;
          }),
      ),
    ].sort((a, b) => a.localeCompare(b)) || []
  );
};

const getMeanMeasurement = (
  latestDataPoints: Record<number, any>,
  room: RoomInfoType,
  field: Sensors,
) =>
  (latestDataPoints &&
    room[field]?.mean &&
    latestDataPoints[room[field]!.mean!]?.value) ||
  undefined;

type LatestDataPointsType = {
  [id: number]: {
    unit?: string;
    timestamp: Date;
    value?: number;
  };
};

const hasFloorOrRoomSensorsData = (
  assets: RoomInfoType[],
  latestDataPoints: LatestDataPointsType,
) => {
  return (
    assets &&
    latestDataPoints &&
    assets.filter((asset: any) => {
      const { temperature, VOC, co2, humidity_sensor, motion, radon } = asset;

      return (
        (temperature &&
          latestDataPoints &&
          typeof latestDataPoints[temperature?.mean]?.value === "number" &&
          latestDataPoints[temperature?.mean]?.timestamp &&
          !isLatestDataOlderThanTwoHours(
            latestDataPoints[temperature?.mean]?.timestamp.valueOf(),
          )) ||
        (VOC &&
          typeof latestDataPoints[VOC.mean]?.value === "number" &&
          latestDataPoints[VOC.mean]?.timestamp &&
          !isLatestDataOlderThanTwoHours(
            latestDataPoints[VOC?.mean]?.timestamp.valueOf(),
          )) ||
        (co2 &&
          typeof latestDataPoints[co2?.mean]?.value === "number" &&
          latestDataPoints[co2?.mean]?.timestamp &&
          !isLatestDataOlderThanTwoHours(
            latestDataPoints[co2?.mean]?.timestamp.valueOf(),
          )) ||
        (humidity_sensor &&
          typeof latestDataPoints[humidity_sensor?.mean]?.value === "number" &&
          latestDataPoints[humidity_sensor?.mean]?.timestamp &&
          !isLatestDataOlderThanTwoHours(
            latestDataPoints[humidity_sensor?.mean]?.timestamp.valueOf(),
          )) ||
        (motion &&
          motion?.value &&
          typeof latestDataPoints[motion.value] === "number") ||
        (radon &&
          typeof latestDataPoints[radon?.mean]?.value === "number" &&
          latestDataPoints[radon?.mean]?.timestamp &&
          !isLatestDataOlderThanTwoHours(
            latestDataPoints[radon?.mean]?.timestamp.valueOf(),
          ))
      );
    })
  );
};

const emptyArr: string[] = [];

export const IndoorClimate = () => {
  const t = useTranslations();
  useBuildingPageTitle(t("indoor-climate.title"));

  const STEPS: Step[] = [
    {
      placement: "top",
      target: ".hint-new-indoorClimate",
      content: t("indoor-climate.onboarding.step-1"),
    },
    {
      placement: "top",
      target: ".ant-table-row",
      content: t("indoor-climate.onboarding.step-2"),
    },
  ];

  const navigate = useNavigate();
  const building = useCurrentBuilding();
  const currentBuildingId = building.id;
  const { overrideUnits, isLoading: isLoadingOverrideUnits } =
    useTimeseriesSettings(currentBuildingId);
  const { client } = useCogniteClient();
  const [search, setSearch] = useState("");
  const [data, setData] = useState<any>();
  const [filterFloor, setFilterFloor] = useState("all");
  const [filterSubBuilding, setFilterSubBuilding] = useState("all");
  const [onboardingSteps, setOnboardingStepCompleted] = useOnboarding(STEPS);
  const { setHelp } = useHelp();
  const [showTimeseriesData, setShowTimeseriesData] = useState<number>();
  const [showCompact, setShowCompact] = useState(true);
  const { height: windowHeight } = useWindowSize();

  const [showRoomInfoFor, setShowRoomInfoFor] = useState<any>();

  const { data: preferences } = useUserSettings();
  const currentPreferences =
    preferences?.indoorClimate?.[currentBuildingId] || emptyArr;

  const toggleSelectedRoom = async (roomId: string) => {
    await mutateUserSettings({
      indoorClimate: {
        [currentBuildingId]: currentPreferences.includes(roomId)
          ? currentPreferences.filter((room) => room !== roomId)
          : [...currentPreferences, roomId],
      },
    });
  };

  useEffect(() => {
    setFilterFloor("all");
    setFilterSubBuilding("all");
    setSearch("");
  }, [currentBuildingId]);

  useEffect(() => {
    const get = async () => {
      const floor = filterFloor === "all" ? "" : ` AND floor = ${filterFloor}`;
      const subBuilding =
        filterSubBuilding === "all"
          ? ""
          : ` AND subBuilding = ${filterSubBuilding}`;
      const rooms = await roomsIndex.search(search, {
        limit: 500,
        filter: `buildingId = ${currentBuildingId}${floor}${subBuilding}`,
        attributesToHighlight: ["*"],
      });

      setData(rooms.hits);
    };

    get();
  }, [client, search, filterSubBuilding, filterFloor, currentBuildingId]);

  const { data: latestDataPoints, isLoading: isLoadingDatapoints } = useSWR(
    data && !isLoadingOverrideUnits
      ? JSON.stringify(
          data
            .map((room: any) => [
              ...getIds(room.temperature),
              ...getIds(room.humidity_sensor),
              ...getIds(room.motion),
              ...getIds(room.VOC),
              ...getIds(room.co2),
              ...getIds(room.radon),
            ])
            .flat(),
        )
      : null,
    getLatestDataPoints(client, overrideUnits),
  );
  const searchTerms = search.trim().split(" ");

  const assets = data
    ?.filter((item: any) => item.floor)
    ?.filter((item: any) => item.subBuilding)
    ?.filter(
      (item: any) =>
        !searchTerms ||
        searchTerms.every(
          (term) =>
            (item.floor || "").toLowerCase().includes(term.toLowerCase()) ||
            (item.name || "").toLowerCase().includes(term.toLowerCase()) ||
            (item.subBuilding || "")
              .toLowerCase()
              .includes(term.toLowerCase()) ||
            (item.description || "")
              .toLowerCase()
              .includes(term.toLowerCase()) ||
            (item.specie || "").toLowerCase().includes(term.toLowerCase()),
        ),
    )
    .filter((item: any) => filterFloor === "all" || item.floor === filterFloor)
    .filter(
      (item: any) =>
        filterSubBuilding === "all" || item.subBuilding === filterSubBuilding,
    );

  const [subBuildings, setSubBuildings] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      setSubBuildings(await getSubBuildings(client, currentBuildingId));
    })();
  }, [client, currentBuildingId]);

  const [floors, setFloors] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      setFloors(await getFloors(client, currentBuildingId, filterSubBuilding));
    })();
  }, [client, currentBuildingId, filterSubBuilding]);

  useEffect(() => {
    setHelp({
      title: t("indoor-climate.title"),
      content: (
        <>
          <p>{t("indoor-climate.help-description")}</p>
        </>
      ),
    });
  }, [t, setHelp]);

  const isLoading = isLoadingDatapoints || !data;

  function getMeanMeasurementSorter(
    roomInfoOne: any,
    roomInfoTwo: any,
    key: "temperature" | "VOC" | "co2" | "radon" | "humidity_sensor",
  ) {
    return (
      (getMeanMeasurement(latestDataPoints, roomInfoOne, key) ?? 0) -
      (getMeanMeasurement(latestDataPoints, roomInfoTwo, key) ?? 0)
    );
  }

  const visibleAssets =
    (showCompact
      ? hasFloorOrRoomSensorsData(assets, latestDataPoints)
      : assets) || [];

  return (
    <>
      <PageHeader
        title={t("indoor-climate.title")}
        extra={
          <Space direction="horizontal">
            <Switch
              onChange={(value) => setShowCompact(value)}
              checkedChildren={t("indoor-climate.toolbar.show-all")}
              unCheckedChildren={t("indoor-climate.toolbar.hide-inactive")}
              data-testid="show-all-rooms"
              checked={showCompact}
            />
            {data && data.length > 0 && (
              <Button
                type="link"
                onClick={() => {
                  navigate(`/asset/${currentBuildingId}/reports/indoorClimate`);
                }}
              >
                {t("indoor-climate.toolbar.create-report")}
              </Button>
            )}
            <Select
              value={filterSubBuilding}
              style={{ width: 160 }}
              onChange={(value) => {
                setFilterSubBuilding(value);
              }}
              aria-label={t("indoor-climate.toolbar.select-sub-building")}
            >
              <Select.Option value="all">
                {t("indoor-climate.toolbar.all-sub-buildings")}
              </Select.Option>
              {subBuildings &&
                subBuildings.map((subBuilding) => (
                  <Select.Option key={subBuilding} value={subBuilding}>
                    {t("indoor-climate.toolbar.sub-building-option", {
                      subBuilding,
                    })}
                  </Select.Option>
                ))}
            </Select>
            <Select
              value={filterFloor}
              style={{ width: 120 }}
              onChange={(value) => {
                setFilterFloor(value);
              }}
              aria-label={t("indoor-climate.toolbar.select-floor")}
              data-testid="floor-select"
            >
              <Select.Option value="all">
                {t("indoor-climate.toolbar.all-floors")}
              </Select.Option>
              {floors &&
                floors.map((floor) => (
                  <Select.Option
                    key={floor}
                    value={floor}
                    data-testid={t("indoor-climate.toolbar.floor-option", {
                      floor,
                    })}
                  >
                    {t("indoor-climate.toolbar.floor-option", { floor })}
                  </Select.Option>
                ))}
            </Select>
            <SearchInput
              allowClear
              style={{ width: 300 }}
              value={search}
              onChange={(evt) => setSearch(evt.target.value)}
              aria-label={t("indoor-climate.toolbar.search-room")}
              placeholder={t("indoor-climate.toolbar.search-room")}
              data-testid="search-room"
            />
            {visibleAssets.length > 0 && (
              <ToggleSidebarButton
                hiddenWhenSidebarVisible
                sidebarHiddenContent={t("notes.show-notes-button")}
              />
            )}
          </Space>
        }
      />
      <CompactContent>
        <SpinnerWithDelay isLoading={isLoading} centerInParent>
          {data && data.length > 0 && (
            <TableWithoutDefaultSort<RoomInfoType>
              scroll={{
                y:
                  windowHeight -
                  ESTIMATED_PAGE_HEADER_HEIGHT -
                  ESTIMATED_TABLE_HEADER_HEIGHT,
              }}
              virtual
              pagination={false}
              onRow={({ id }) => ({
                onClick: () => {
                  setShowRoomInfoFor({ id, sensors: [] });
                },
              })}
              locale={{
                emptyText: showCompact
                  ? t("indoor-climate.compact-no-results-hint")
                  : undefined,
              }}
              columns={[
                {
                  key: "checkbox",
                  defaultSortOrder: "ascend",
                  width: 32,
                  sorter: ({ id: idOne }, { id: idTwo }) => {
                    if (currentPreferences.includes(idOne)) {
                      return currentPreferences.includes(idTwo) ? 0 : -1;
                    }
                    return currentPreferences.includes(idTwo) ? 1 : 0;
                  },
                  render: (_, { id }) =>
                    currentPreferences.includes(id) ? (
                      <StarFilled
                        style={{ color: DEFAULT_GLOBAL_THEME.defaultColor }}
                        onClick={(event) => {
                          event.stopPropagation();
                          toggleSelectedRoom(id);
                        }}
                      />
                    ) : (
                      <StarOutlined
                        onClick={(event) => {
                          event.stopPropagation();
                          toggleSelectedRoom(id);
                        }}
                      />
                    ),
                },
                {
                  title: t("indoor-climate.table-column-title.building"),
                  key: "subBuilding",
                  width: 50,
                  sorter: (
                    { subBuilding: subBuildingOne },
                    { subBuilding: subBuildingTwo },
                  ) => sortString(subBuildingOne, subBuildingTwo),
                  render: (_, { subBuilding }) =>
                    subBuilding && (
                      <ProperateHighlighter
                        searchWords={search.split(" ")}
                        autoEscape
                        textToHighlight={subBuilding}
                      />
                    ),
                },
                {
                  title: t("indoor-climate.table-column-title.floor"),
                  key: "floor",
                  width: 60,
                  sorter: ({ floor: floorOne }, { floor: floorTwo }) =>
                    floorOne.localeCompare(floorTwo),
                  render: (_, { floor }) => (
                    <ProperateHighlighter
                      searchWords={search.split(" ")}
                      autoEscape
                      textToHighlight={floor}
                    />
                  ),
                },
                {
                  title: t("indoor-climate.table-column-title.name"),
                  key: "room",
                  width: 200,
                  sorter: ({ name: nameOne }, { name: nameTwo }) =>
                    nameOne.localeCompare(nameTwo),
                  render: (_, { name }) => (
                    <ProperateHighlighter
                      searchWords={search.split(" ")}
                      autoEscape
                      textToHighlight={name}
                    />
                  ),
                },
                {
                  title: t("indoor-climate.table-column-title.type"),
                  key: "roomType",
                  width: 150,
                  sorter: ({ specie: specieOne }, { specie: specieTwo }) =>
                    specieOne.localeCompare(specieTwo),
                  render: (_, { specie }) => (
                    <ProperateHighlighter
                      searchWords={search.split(" ")}
                      autoEscape
                      textToHighlight={specie}
                    />
                  ),
                },
                {
                  title: t("indoor-climate.table-column-title.temperature"),
                  key: "temperature",
                  width: VALUE_COL_WIDTH,
                  align: "right",
                  sorter: (roomInfoOne, roomInfoTwo) =>
                    getMeanMeasurementSorter(
                      roomInfoOne,
                      roomInfoTwo,
                      "temperature",
                    ),
                  render: (_, { temperature }) => {
                    return (
                      latestDataPoints &&
                      temperature &&
                      temperature.mean && (
                        <IndoorClimateCustomTooltip
                          onClick={(event) => {
                            event.stopPropagation();
                            setShowTimeseriesData(temperature.mean);
                          }}
                          max={latestDataPoints[temperature.max!]}
                          min={latestDataPoints[temperature.min!]}
                          average={latestDataPoints[temperature.mean]}
                        />
                      )
                    );
                  },
                },
                {
                  title: t("indoor-climate.table-column-title.voc"),
                  key: "VOC",
                  width: VALUE_COL_WIDTH,
                  align: "right",
                  sorter: (roomInfoOne, roomInfoTwo) =>
                    getMeanMeasurementSorter(roomInfoOne, roomInfoTwo, "VOC"),
                  render: (_, { VOC: voc }) => {
                    return (
                      (latestDataPoints && voc && voc.mean && (
                        <IndoorClimateCustomTooltip
                          onClick={(event) => {
                            event.stopPropagation();
                            setShowTimeseriesData(voc.mean);
                          }}
                          max={latestDataPoints[voc.max!]}
                          min={latestDataPoints[voc.min!]}
                          average={latestDataPoints[voc.mean]}
                        />
                      )) ||
                      ""
                    );
                  },
                },
                {
                  title: t("indoor-climate.table-column-title.co2"),
                  key: "co2",
                  align: "right",
                  width: VALUE_COL_WIDTH,
                  sorter: (roomInfoOne, roomInfoTwo) =>
                    getMeanMeasurementSorter(roomInfoOne, roomInfoTwo, "co2"),
                  render: (_, { co2 }) => {
                    return (
                      (latestDataPoints && co2 && co2.mean && (
                        <IndoorClimateCustomTooltip
                          onClick={(event) => {
                            event.stopPropagation();
                            setShowTimeseriesData(co2.mean);
                          }}
                          max={latestDataPoints[co2.max!]}
                          min={latestDataPoints[co2.min!]}
                          average={latestDataPoints[co2.mean]}
                        />
                      )) ||
                      ""
                    );
                  },
                },
                {
                  title: t("indoor-climate.table-column-title.radon"),
                  key: "radon",
                  align: "right",
                  width: VALUE_COL_WIDTH,
                  sorter: (roomInfoOne, roomInfoTwo) =>
                    getMeanMeasurementSorter(roomInfoOne, roomInfoTwo, "radon"),
                  render: (_, { radon }) => {
                    return (
                      (latestDataPoints && radon && radon.mean && (
                        <IndoorClimateCustomTooltip
                          onClick={(event) => {
                            event.stopPropagation();
                            setShowTimeseriesData(radon.mean);
                          }}
                          max={latestDataPoints[radon.max!]}
                          min={latestDataPoints[radon.min!]}
                          average={latestDataPoints[radon.mean]}
                        />
                      )) ||
                      ""
                    );
                  },
                },
                {
                  title: t("indoor-climate.table-column-title.humidity"),
                  key: "humidity_sensor",
                  align: "right",
                  width: VALUE_COL_WIDTH,
                  sorter: (roomInfoOne, roomInfoTwo) =>
                    getMeanMeasurementSorter(
                      roomInfoOne,
                      roomInfoTwo,
                      "humidity_sensor",
                    ),
                  render: (_, { humidity_sensor: humditySensor }) => {
                    return (
                      (latestDataPoints &&
                        humditySensor &&
                        humditySensor.mean && (
                          <IndoorClimateCustomTooltip
                            onClick={(event) => {
                              event.stopPropagation();
                              setShowTimeseriesData(humditySensor.mean);
                            }}
                            max={latestDataPoints[humditySensor.max!]}
                            min={latestDataPoints[humditySensor.min!]}
                            average={latestDataPoints[humditySensor.mean]}
                          />
                        )) ||
                      ""
                    );
                  },
                },
                {
                  title: t("indoor-climate.table-column-title.motion"),
                  key: "motion",
                  align: "right",
                  width: VALUE_COL_WIDTH,
                  sorter: ({ motion: motionOne }, { motion: motionTwo }) => {
                    return (
                      ((latestDataPoints &&
                        motionOne?.value &&
                        latestDataPoints[motionOne.value]?.value) ??
                        0) -
                      ((latestDataPoints &&
                        motionTwo?.value &&
                        latestDataPoints[motionTwo.value]?.value) ??
                        0)
                    );
                  },

                  render: (_, { motion }) => {
                    return (
                      latestDataPoints &&
                      motion &&
                      motion.value && (
                        <Button
                          type="text"
                          onClick={(event) => {
                            event.stopPropagation();
                            setShowTimeseriesData(motion.value);
                          }}
                        >
                          {formatMeasurement(latestDataPoints[motion.value])}
                        </Button>
                      )
                    );
                  },
                },
                {
                  key: "checkbox",
                  width: 32,
                  render: (_, { id }) => (
                    <IndoorClimateTableNotesColumn id={parseInt(id)} />
                  ),
                },
              ]}
              dataSource={visibleAssets}
              rowKey="id"
              rowIsHoverable
            />
          )}
        </SpinnerWithDelay>
        {building.dataSetId && visibleAssets.length > 0 && (
          <NotesSidebar
            noteSource={NoteSource.WEB_INDOOR_CLIMATE}
            assetFilterMode={NotesAssetFilterMode.AssetList}
            idSet={new Set(visibleAssets.map((asset: any) => asset.id))}
            buildings={[{ id: building.dataSetId, name: building.name }]}
          />
        )}
        {showRoomInfoFor && (
          <RoomInfo
            {...showRoomInfoFor}
            hide={() => setShowRoomInfoFor(undefined)}
            openGraph={(ts: number) => setShowTimeseriesData(ts)}
          />
        )}
        {onboardingSteps && onboardingSteps.length > 0 && (
          <JoyrideWrapper
            content={onboardingSteps[0]}
            onClose={setOnboardingStepCompleted}
          />
        )}
      </CompactContent>
      {showTimeseriesData && (
        <GraphModal
          timeseriesInfo={{
            id: showTimeseriesData,
          }}
          hide={() => setShowTimeseriesData(undefined)}
          buildingId={currentBuildingId}
        />
      )}
    </>
  );
};
type TooltipProps = {
  max: Measurement;
  min: Measurement;
  average: Measurement;
  onClick: (event: any) => void;
};
const isLatestDataOlderThanTwoHours = (latestDataTime: number) => {
  const twoHoursAgo = dayjs().subtract(2, "hours").valueOf();
  return latestDataTime < twoHoursAgo;
};

const isLatestDataOlderThanAWeek = (latestDataTime: number) => {
  const oneWeekAgo = dayjs().subtract(1, "week").valueOf();
  return latestDataTime < oneWeekAgo;
};

const IndoorClimateCustomTooltip = ({
  max,
  min,
  average,
  onClick,
}: TooltipProps) => {
  const latestDataOlderThanTwoHours =
    average?.timestamp &&
    isLatestDataOlderThanTwoHours(average?.timestamp.valueOf());
  const latestDataOlderThanAWeek =
    average?.timestamp &&
    isLatestDataOlderThanAWeek(average?.timestamp.valueOf());
  const t = useTranslations();
  const lastDataPointFromNow = dayjs(average?.timestamp).fromNow();
  const maxFormattedMeasurement = formatMeasurement(max);
  const minFormattedMeasurement = formatMeasurement(min);

  return (
    <Tooltip
      title={
        <div>
          {latestDataOlderThanTwoHours ? (
            <span>
              {t("indoor-climate.tooltip-last-data-point", {
                lastDataPointFromNow,
              })}
            </span>
          ) : (
            <>
              <p>
                {t("indoor-climate.tooltip-max-value", {
                  maxFormattedMeasurement,
                })}
              </p>
              <p>
                {" "}
                {t("indoor-climate.tooltip-min-value", {
                  minFormattedMeasurement,
                })}
              </p>
            </>
          )}
        </div>
      }
    >
      <Button type="text" onClick={onClick}>
        {latestDataOlderThanAWeek ? (
          ""
        ) : latestDataOlderThanTwoHours ? (
          <WarningText>
            <span style={{ fontSize: 12, color: "yellow" }}>
              <ClockCircleOutlined />
            </span>{" "}
            <span style={{ marginLeft: 5, textDecoration: "line-through" }}>
              {formatMeasurement(average)}
            </span>
          </WarningText>
        ) : (
          formatMeasurement(average)
        )}
      </Button>
    </Tooltip>
  );
};
