import { CSSProperties, FC, useContext } from "react";
import { ThemeContext } from "styled-components";
import { Tooltip } from "antd";
import { CustomDndProvider } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { ItemTypes } from "./ItemTypes";

export interface SensorProps {
  name: string;
  icon: JSX.Element;
  type: string;
  family?: string;
  color?: string;
}

export const Sensor: FC<SensorProps> = function Sensor({
  name,
  icon,
  type,
  family = ItemTypes.SENSOR,
  color,
}) {
  const t = useTranslations();
  const themeContext = useContext(ThemeContext);
  const [{ isDragging }, drag] = CustomDndProvider.useDrag(
    {
      type: family,
      item: { name, icon, type },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    },
    [name, icon, type],
  );

  const style: CSSProperties = {
    border: `1px dashed ${themeContext.neutral5}`,
    backgroundColor: `${themeContext.neutral10}`,
    color: color ?? `${themeContext.neutral0}`,
    padding: "5px",
    fontSize: "28px",
    lineHeight: "32px",
    marginRight: "20px",
    marginBottom: "20px",
    cursor: "move",
    float: "left",
    width: "42px",
    height: "42px",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const opacity = isDragging ? 0.4 : 1;
  return (
    <Tooltip
      title={t("floor-plan.drag-and-drop-sensor", {
        name: name,
      })}
    >
      <div
        ref={drag}
        style={{ ...style, opacity }}
        data-testid={`sensor-${name}`}
      >
        {icon}
      </div>
    </Tooltip>
  );
};
