import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useFloorPlanEditMode,
  useFloorPlanIsMovingPin,
} from "./FloorPlanContext";
import { useFloorPlanMapMoveHandlers } from "./FloorPlanMapContext";

type Context = [boolean, Dispatch<SetStateAction<boolean>>];

const PopoverContext = createContext<Context>([
  false,
  () => console.warn("Popover context not initialized"),
]);

export function FloorPlanPinPopoverProvider(props: PropsWithChildren) {
  const state = useState(false);

  return (
    <PopoverContext.Provider value={state}>
      {props.children}
    </PopoverContext.Provider>
  );
}

export function useFloorPlanPinPopover(showWhileEditing = false) {
  const [isEditing] = useFloorPlanEditMode();
  const [isPopoverOpen, setPopoverOpen] = useContext(PopoverContext);
  const moveHandlers = useFloorPlanMapMoveHandlers();
  const lastIsMovingChange = useRef(Date.now());
  const [isMoving] = useFloorPlanIsMovingPin();

  useEffect(() => {
    lastIsMovingChange.current = Date.now();
  }, [isMoving]);

  useEffect(() => {
    const handler = () => setPopoverOpen(false);

    moveHandlers.add(handler);

    return () => {
      moveHandlers.delete(handler);
    };
  }, [moveHandlers, setPopoverOpen]);

  function handleOpenChange(open: boolean) {
    const justMoved = lastIsMovingChange.current > Date.now() - 100;

    if (isEditing && (!showWhileEditing || justMoved)) {
      return;
    }

    setPopoverOpen(open);
  }

  return [isPopoverOpen, handleOpenChange] as const;
}
