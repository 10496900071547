import { FocusEvent } from "react";
import { Node, useReactFlow } from "reactflow";
import { Button, InputNumber } from "antd";
import { useTranslations } from "@properate/translations";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { getNodeId, updateReactFlowNodeDataPartial } from "./helpers/Utils";
import {
  NodeOutput,
  NodeInput,
  Header,
  Body,
  NodeOutputLabel,
  NodeInputLabel,
  LargeNode,
  NodeContent,
} from "./helpers/NodeComponents";
import { XYPlot } from "./components/transform/XYPlot";

interface TransformNodeProps {
  operationId: string;
  xp: number[];
  fp: number[];
}

export const getEmptyTransformNode = (): Node<TransformNodeProps> => {
  return {
    id: getNodeId("transform"),
    type: "transform",
    data: {
      operationId: "transform",
      xp: [0, 1],
      fp: [0, 1],
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function TransformNode(params: { id: string; data: TransformNodeProps }) {
  const t = useTranslations();
  const reactFlowInstance = useReactFlow();

  const handleXValueChange = (x: number | null, i: number) => {
    if (x !== null) {
      const xp = [...params.data.xp];
      const fp = [...params.data.fp];
      xp[i] = x;

      // Sort array by x values
      const indices = Array.from(xp.keys());
      indices.sort((a, b) => xp[a] - xp[b]);

      const sortedXp = indices.map((i) => xp[i]);
      const sortedFp = indices.map((i) => fp[i]);

      updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
        xp: sortedXp,
        fp: sortedFp,
      });
    }
  };

  const handleFValueChange = (f: number | null, i: number) => {
    if (f !== null) {
      const fp = [...params.data.fp];
      fp[i] = f;
      updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
        fp,
      });
    }
  };

  function handleAdd(): void {
    const xp = [
      ...params.data.xp,
      params.data.xp[params.data.xp.length - 1] + 1,
    ];
    const fp = [
      ...params.data.fp,
      params.data.fp[params.data.fp.length - 1] + 1,
    ];
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      xp,
      fp,
    });
  }

  function handleDelete(i: number): void {
    const xp = [...params.data.xp];
    const fp = [...params.data.fp];
    xp.splice(i, 1);
    fp.splice(i, 1);
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      xp,
      fp,
    });
  }

  const table = params.data.xp.map((x, i) => {
    return (
      <tr key={x}>
        <td>
          <InputNumber
            controls={false}
            value={x}
            // onChange={(x: number | null) => handleXValueChange(x, i)}
            onBlur={(event: FocusEvent<HTMLInputElement>) =>
              handleXValueChange(Number(event.target.value), i)
            }
          />
        </td>
        <td>
          <InputNumber
            controls={false}
            value={params.data.fp[i]}
            onChange={(f: number | null) => handleFValueChange(f, i)}
          />
        </td>
        <td>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(i)}
            disabled={i < 2}
          />
        </td>
      </tr>
    );
  });

  return (
    <LargeNode>
      <Header>{t("calculation-flow.node-types.transform")}</Header>
      <Body>
        <NodeInput inputId="a">
          <NodeInputLabel>A</NodeInputLabel>
        </NodeInput>
        <XYPlot x={params.data.xp} y={params.data.fp} />
        <NodeContent>
          <table>
            <tr>
              <th>{"X-Verdier"}</th>
              <th>{"Y-Verdier"}</th>
            </tr>
            {table}
          </table>
          <Button icon={<PlusOutlined />} onClick={() => handleAdd()} />
        </NodeContent>
        <NodeOutput>
          <NodeOutputLabel>
            {t("calculation-flow.node-types.transform-result")}
          </NodeOutputLabel>
        </NodeOutput>
      </Body>
    </LargeNode>
  );
}

export default TransformNode;
