import { Button, Spin } from "antd";
import { useTranslations } from "@properate/translations";
import { ReactNode, useEffect } from "react";
import {
  formatSubBuildingFromExternalId,
  getSystemCodeFromExternalId,
} from "@properate/common";
import { useUser } from "@properate/auth";
import { FormContextActionType } from "@/pages/alarms/details/FormContext";
import { useFormContext, useFormEntry } from "../../FormContext/hooks";
import { useTimeseriesWithDatapoint } from "../../hooks";

export function SelectedTimeseries({
  name,
  label,
  activateSelector,
}: {
  name: string;
  label: ReactNode;
  activateSelector: () => void;
}) {
  const { dispatch } = useFormContext();
  const user = useUser();
  const {
    value: timeseriesId,
    hasError,
    errorMessage,
  } = useFormEntry<number>(name) ?? {};

  useEffect(
    () => {
      if (hasError) {
        dispatch({ type: FormContextActionType.validateField, id: name });
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [timeseriesId],
  );

  const { timeseries, latestDatapoint, isLoading, error } =
    useTimeseriesWithDatapoint({
      timeseriesId,
    });

  const t = useTranslations();
  const tBase = "alarm-details.select-timeseries.";
  function EditButton() {
    if (!timeseries) {
      return null;
    }
    return (
      <Button size={"small"} onClick={activateSelector}>
        {t(`ui.edit`)}
      </Button>
    );
  }

  function SelectedValues() {
    if (!timeseries) {
      return null;
    }

    function makeEntry(
      key: "subBuilding" | "name" | "measurement",
      value?: string,
    ) {
      return {
        label: t(`${tBase}${key}`),
        value: value,
        key,
      };
    }

    const timeseriesLabel = `${getSystemCodeFromExternalId(
      timeseries?.externalId ?? "",
    )} ${timeseries.description ?? ""} ${timeseries.name ?? ""}`;

    const fields = [
      makeEntry(
        "subBuilding",
        formatSubBuildingFromExternalId(timeseries?.externalId ?? ""),
      ),
      makeEntry("name", timeseriesLabel),
      makeEntry("measurement", latestDatapoint),
    ];
    return (
      <dl className={"grid grid-cols-3 gap-4 w-full m-0"}>
        {fields.map((field) => (
          <div key={field.key} className={"flex flex-col gap-2"}>
            <dt className="m-0 font-bold">{field.label}</dt>
            <dd className="m-0">{field.value}</dd>
          </div>
        ))}
      </dl>
    );
  }

  function OpenSelectButton() {
    if (timeseries) {
      return null;
    }
    return (
      <div className={"w-full flex justify-center items-center"}>
        <Button type="primary" size="large" ghost onClick={activateSelector}>
          {t(`${tBase}select-button`)}
        </Button>
      </div>
    );
  }

  function ValidationErrorMessage() {
    if (!hasError) {
      return null;
    }
    return <p className={"h-5 w-full text-error"}>{errorMessage}</p>;
  }

  if (error) {
    return (
      <div className="h-5 w-full text-error">
        {t("pages.error.default.title")}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={"flex flex-col w-full content-center justify-center"}>
        <Spin />
      </div>
    );
  }

  return (
    <div className={"flex flex-col w-full gap-2"}>
      <h2 className={"w-full flex justify-between align-center m-0"}>
        {label}
        {user.isAdmin && <EditButton />}
      </h2>

      <SelectedValues />
      <OpenSelectButton />
      <ValidationErrorMessage />
    </div>
  );
}
