import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Timeseries } from "@cognite/sdk";
import { cn } from "@properate/ui";
import {
  AutoSizedSimpleGraph,
  SimpleGraphContextProvider,
} from "@/features/analysis";
import {
  useAlarmGraphLegendData,
  useTimeseries,
  useAlarmDetailsTimeSpan,
  useGraphHighlightsForEvents,
} from "../../hooks";
import { useFormValue } from "../../FormContext/hooks";
import { AlarmTimeseriesNavigator } from "./AlarmTimeseriesNavigator";
import { AlarmTimeseriesGraphLayout } from "./AlarmTimeseriesGraphLayout";
import { getColor } from "./utils";
import AlarmTimeseriesEmpty from "./AlarmTimeseriesEmpty";

type Props = {
  tsName: string;
  thresholdName: string;
};

function SingleTimeseriesWithThresholdGraphImpl({
  tsName,
  thresholdName,
}: Props) {
  const [tsId] = useFormValue<number>(tsName);
  const [threshold] = useFormValue<number>(thresholdName);
  const themeContext = useContext(ThemeContext);
  const { timeseries } = useTimeseries({
    timeseriesId: tsId,
  });
  const timeSpan = useAlarmDetailsTimeSpan();
  const highlights = useGraphHighlightsForEvents();
  const timeseriesColor = getColor(0, themeContext);

  const legendData = useAlarmGraphLegendData({
    timeseriesList: [timeseries],
    timeseriesSettings: {
      [tsId]: {
        color: timeseriesColor,
        labelKey: "ui.value",
      },
      [threshold]: {
        color: "black",
        labelKey: "ui.threshold",
      },
    },
  });

  function renderGraph() {
    return (
      <>
        <AutoSizedSimpleGraph
          interactive
          timeSpan={timeSpan}
          highlights={highlights}
          settingsTimeseriesList={[
            {
              id: tsId,
              hidden: false,
              color: timeseriesColor,
              unitSelected: timeseries?.unit ?? "",
            },
          ]}
          markersForTimeseries={{
            [tsId]: {
              value: threshold,
              className: cn(
                "stroke-black dark:stroke-white opacity-60 dark:opacity-100",
              ),
            },
          }}
          legendData={legendData}
        />
      </>
    );
  }

  function renderNavigator() {
    const tslist = [
      {
        timeseries: timeseries as Timeseries,
        color: timeseriesColor,
      },
    ];
    return <AlarmTimeseriesNavigator timeseriesWithDataPointsList={tslist} />;
  }

  if (!tsId || !timeseries) {
    return <AlarmTimeseriesEmpty />;
  }

  return (
    <AlarmTimeseriesGraphLayout
      graph={renderGraph()}
      navigator={renderNavigator()}
    />
  );
}

export default function SingleTimeseriesWithThresholdGraph(props: Props) {
  return (
    <SimpleGraphContextProvider>
      <SingleTimeseriesWithThresholdGraphImpl {...props} />
    </SimpleGraphContextProvider>
  );
}
