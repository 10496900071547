import { useState } from "react";
import Icon, { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { CalculationFlow } from "@/pages/calculationFlow/types";
import { deleteCalculationFlow, deleteManualTimeseries } from "@/eepApi";
import { ReactComponent as CloudAutomationSvg } from "../../../layout/icons/cloud_automation.svg";
import { DeleteModal } from "./DeleteModal";
import { EditMeasureModal } from "./EditMeasureModal";
import type { MenuProps } from "antd";

type MenuItem = Required<MenuProps>["items"][number];

interface Props {
  flow: CalculationFlow;
  onChange: () => void;
}

export const ActionsMenu = ({ flow, onChange }: Props) => {
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key === "delete") setDeleteModalOpen(true);
    else if (e.key === "editMeasure") setEditModalOpen(true);
    else if (e.key === "editCalculationFlow")
      navigate(`../flexAutomation/${flow.id}`);
  };

  const items: MenuItem[] = [
    {
      key: "editCalculationFlow",
      icon: (
        <Icon component={CloudAutomationSvg} style={{ fontSize: "120%" }} />
      ),
    },
    {
      key: "editMeasure",
      // label: t("energy-flexing.measures.edit"),
      icon: <EditOutlined style={{ fontSize: "120%" }} />,
    },
    {
      key: "delete",
      // label: t("energy-flexing.measures.delete"),
      icon: <DeleteOutlined style={{ fontSize: "120%" }} />,
    },
  ];

  function handleChange(): void {
    setEditModalOpen(false);
    onChange();
  }

  return (
    <>
      <Menu
        onClick={onClick}
        mode="horizontal"
        items={items}
        style={{ width: 250, paddingInline: 0 }}
      />
      {deleteModalOpen && (
        <DeleteModal
          flow={flow}
          onClose={() => setDeleteModalOpen(false)}
          onDelete={async (flow: CalculationFlow) => {
            if (flow.id !== null) {
              // Delete flex automation
              await deleteCalculationFlow(flow.id);
              // Delete virtual setpoint
              if (flow.metadata)
                await deleteManualTimeseries(
                  flow.metadata.activationExternalId,
                );
            }
            setDeleteModalOpen(false);
            onChange();
          }}
        />
      )}
      {editModalOpen && (
        <EditMeasureModal
          flow={flow}
          onClose={() => setEditModalOpen(false)}
          onChange={handleChange}
        />
      )}
    </>
  );
};
