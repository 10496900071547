import { AlarmRuleTypeName } from "@properate/common";
import { RichTextFormat } from "@properate/ui";
import { TextInfoBox } from "../components/InfoBox";
import { tKey } from "./common/utils";
import { ThirdPartyAlarmDefinition } from "./ThirdPartyAlarm";
import { ResponseDelaySelect } from "./common/SelectOffset";
import { AlarmRuleType, TypeDescriptionProps } from "./index";

function LockedThirdPartyAlarmMessage({
  disabledFormatters,
  formatOverrides,
  hasDuration,
}: TypeDescriptionProps) {
  return (
    <RichTextFormat
      message={
        hasDuration
          ? "alarm-details.alarm-types.third-party-alarm.locked.type-description-with-duration"
          : "alarm-details.alarm-types.third-party-alarm.locked.type-description"
      }
      disabledFormatters={disabledFormatters}
      formatOverrides={formatOverrides}
    />
  );
}

export const LockedThirdPartyAlarmDefinition: AlarmRuleType = {
  ...ThirdPartyAlarmDefinition,
  name: AlarmRuleTypeName.LockedThirdPartyAlarm,
  TypeDescription: LockedThirdPartyAlarmMessage,
  labelTranslationKey: tKey("third-party-alarm.locked.type-label"),
  lockedSchedule: false,
  lockedType: true,
  formComponent: <LockedThirdPartyAlarmInfo />,
};

function LockedThirdPartyAlarmInfo() {
  return (
    <>
      <TextInfoBox message={tKey("third-party-alarm.locked.settings-info")} />
      <ResponseDelaySelect />
    </>
  );
}
