import { useTranslations } from "@properate/translations";
import { useCallback, useEffect, useState } from "react";
import { App } from "antd";
import { Datapoints } from "@cognite/sdk";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { getCalculationFlows } from "@/eepApi";
import { isErrorWithMessage } from "@/utils/api";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { CalculationFlow } from "../calculationFlow/types";
import { MeasureSummary } from "./components/MeasureSummary";
import { MeasuresTable } from "./components/MeasuresTable";

export const FlexAutomation = () => {
  const { notification } = App.useApp();
  const { client } = useCogniteClient();
  const t = useTranslations();
  const building = useCurrentBuilding();
  const [flexAutomations, setFlexAutomations] = useState<CalculationFlow[]>([]);
  const [activations, setActivations] = useState<Datapoints[]>([]);
  const [loading, setLoading] = useState(true);

  useBuildingPageTitle(t("energy-flexing.title"));

  const fetchFlows = useCallback(async () => {
    setLoading(true);
    try {
      if (building.id) {
        setFlexAutomations([]);
        const flows = await getCalculationFlows(
          Number(building.id),
          "flexAutomation",
        );
        setFlexAutomations(flows);
      }
    } catch (error) {
      if (isErrorWithMessage(error)) {
        return notification.error({
          message: error.message,
        });
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [building.id, notification]);

  const fetchActivations = useCallback(async () => {
    const activationExternalIds = flexAutomations.map((flow) => {
      return { externalId: flow.metadata!.activationExternalId };
    });
    if (activationExternalIds.length === 0) setActivations([]);
    else
      setActivations(
        await client.datapoints.retrieveLatest(activationExternalIds, {
          ignoreUnknownIds: true,
        }),
      );
  }, [flexAutomations, client]);

  useEffect(() => {
    fetchFlows();
  }, [fetchFlows]);

  useEffect(() => {
    fetchActivations();
  }, [fetchActivations]);

  return (
    <>
      <h1>{t("energy-flexing.title")}</h1>
      <MeasureSummary flows={flexAutomations} activations={activations} />
      <MeasuresTable
        flows={flexAutomations}
        activations={activations}
        onReloadFlows={fetchFlows}
        onReloadActivations={fetchActivations}
        loading={loading}
      />
    </>
  );
};
