import { useRef } from "react";
import { message } from "antd";
import { useTranslations } from "@properate/translations";
import { CustomDndProvider } from "@properate/ui";
import { usePanZoomAreaApi } from "./PanZoomAreaApiContext";

export const ITEM_LINK_FAMILY = "link";

export type OnDropParams = {
  x: number;
  y: number;
};

type Props = {
  onDrop: (params: OnDropParams) => void;
  children: React.ReactNode;
};

export function PanZoomAreaDnD(props: Props) {
  const t = useTranslations();
  const api = usePanZoomAreaApi();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [, drop] = CustomDndProvider.useDrop({
    accept: ITEM_LINK_FAMILY,
    drop(item, monitor) {
      if (api === null || containerRef.current === null) {
        message.warning(t("floor-plan-v2.errors.required-references-not-set"));
        return;
      }

      const dropOffset = monitor.getClientOffset();
      const containerRect = containerRef.current.getBoundingClientRect();

      if (dropOffset === null) {
        message.warning(
          t("floor-plan-v2.errors.drop-offset-failed-to-compute"),
        );
        return;
      }

      const { x: offsetX, y: offsetY } = dropOffset;
      const { left: containerLeft, top: containerTop } = containerRect;

      const { x: panX, y: panY } = api.getPosition();
      const zoom = api.getZoom();

      const x = (offsetX - containerLeft - panX) / zoom;
      const y = (offsetY - containerTop - panY) / zoom;

      props.onDrop({ x, y });
    },
  });

  return (
    <div
      ref={(el) => {
        drop(el);
        containerRef.current = el;
      }}
    >
      {props.children}
    </div>
  );
}
