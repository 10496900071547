import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { formatSubBuildingFromExternalId } from "@properate/common";
import { CogniteClient } from "@cognite/sdk";
import { AimOutlined, LinkOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { useSchema } from "@/services/schemas";
import { ACCENT2, ACCENT8 } from "@/utils/ProperateColors";
import { ITEM_LINK_FAMILY } from "@/components/PanZoom/PanZoomAreaDnD";
import { processImage, SchemaView } from "../common/SchemaView/SchemaView";
import { useHelp } from "../../context/HelpContext";
import { getAssetsWithLabels } from "../../utils/helpers";
import { useCogniteClient } from "../../context/CogniteClientContext";
import { Sensor } from "../common/SchemaView/TechnicalSchema/Sensor";
import { useBuildingPageTitle } from "../../hooks/usePageTitle";

export const TechnicalSchemaView = () => {
  const t = useTranslations();
  useBuildingPageTitle(t("floor-plan.technical-schema-view.title"));
  const { client } = useCogniteClient();
  const { id, fileId, view }: any = useParams();
  const [buildings, setBuildings] = useState<string[]>([]);
  const { setHelp } = useHelp();
  const [image, setImage] = useState<HTMLElement>();
  const { schema, error, update, archive } = useSchema(
    "technicalSchema",
    fileId,
  );

  if (error) {
    console.error(error);
  }

  useEffect(() => {
    setHelp({
      title: t("floor-plan.technical-schema-view.title"),
      content: t.rich("floor-plan.technical-schema-view.help.view-page", {
        p: (text) => <p>{text}</p>,
      }),
    });
  }, [setHelp, t]);

  useEffect(() => {
    const get = async (client: CogniteClient) => {
      const assets = [
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "ventilation_unit",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "cooling_system",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "heating_system",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "sanitary_system",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "process_cooling",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "outdoor_heating_system",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "low_voltage_supply",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "cooling_production",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "humidity_sensor",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), ["schedule"])),
        ...(await getAssetsWithLabels(client, parseInt(id), ["set_point"])),
      ];

      const subBuildings = assets?.reduce((prev, current) => {
        const subBuilding = formatSubBuildingFromExternalId(
          current.externalId!,
        );
        if (subBuilding !== "Common" && !prev.includes(subBuilding)) {
          return [...prev, subBuilding];
        }
        return prev;
      }, [] as string[]);
      setBuildings(subBuildings);
    };

    get(client);
  }, [client, id]);

  useEffect(() => {
    if (schema) {
      setImage(processImage(schema.image));
    }
  }, [schema]);

  return (
    <>
      {schema && (
        <SchemaView
          title={t("floor-plan.technical-schema-view.title")}
          id={id}
          fileId={fileId}
          type="technicalSchema"
          view={view}
          buildings={buildings}
          schema={schema}
          update={update}
          archive={archive}
          availableSensors={[
            <Sensor
              name={t("floor-plan.technical-schema-view.sensor-gauge-general")}
              icon={<AimOutlined />}
              type="gauge-general"
              key="gauge-general"
            />,
            <Sensor
              color={ACCENT2}
              name={t(
                "floor-plan.technical-schema-view.sensor-set-point-general",
              )}
              icon={<AimOutlined />}
              type="set-point-general"
              key="set-point-general"
            />,
            <Sensor
              family={ITEM_LINK_FAMILY}
              color={ACCENT8}
              name="Link"
              icon={<LinkOutlined />}
              type="link"
              key="link"
            />,
          ]}
          image={image}
          setImage={setImage}
        />
      )}
    </>
  );
};
