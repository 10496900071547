import { Space } from "antd";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { DERIVED_UNITS } from "@properate/common";
import { useEffect, useState } from "react";
import { InputWithError } from "@/pages/common/SchemaGraph/InputWithError";
import { AlarmSubtitle } from "./elements";

interface Props {
  value?: number;
  unit: string;
  originalUnit: string;
  error?: string;
  onChange: (value: number | undefined) => unknown;
  type: "max" | "min";
}

export const AlarmComparisonValue = ({
  value,
  originalUnit,
  unit,
  error,
  onChange,
  type,
}: Props) => {
  const t = useTranslations();
  const user = useUser();
  const [localValue, setLocalValue] = useState<number | undefined>(() =>
    typeof value === "number" && unit !== originalUnit
      ? DERIVED_UNITS[originalUnit][unit].to(value)
      : value,
  );

  useEffect(() => {
    setLocalValue(
      typeof value === "number" && unit !== originalUnit
        ? DERIVED_UNITS[originalUnit][unit].to(value)
        : value,
    );
  }, [value, unit, originalUnit]);

  function handleChange(value: string) {
    setLocalValue(value === "" ? undefined : Number(value));
    onChange(
      value === ""
        ? undefined
        : unit !== originalUnit
        ? DERIVED_UNITS[originalUnit][unit].from(Number(value))
        : Number(value),
    );
  }

  const description =
    type === "max"
      ? t(
          "common.timeseries-graph-modal.alarm-fields.trigger-when-value-higher-than",
        )
      : t(
          "common.timeseries-graph-modal.alarm-fields.trigger-when-value-lower-than",
        );

  return (
    <>
      <AlarmSubtitle>
        {t("common.timeseries-graph-modal.alarm-fields.setup")}
      </AlarmSubtitle>
      <Space direction="vertical">
        <span>{description}</span>
        <InputWithError
          disabled={user.isViewer}
          type="number"
          value={localValue}
          error={error}
          suffix={unit}
          placeholder="30"
          onChange={handleChange}
          dataTestId={"alarm_threshold_value"}
        />
      </Space>
    </>
  );
};
