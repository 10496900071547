import { useUser } from "@properate/auth";
import { useState, useEffect, ChangeEvent, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { App, Button, Space, Input } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { PlusOutlined } from "@ant-design/icons";
import {
  sortString,
  ClipboardButton,
  ToggleSidebarButton,
} from "@properate/ui";
import { DoubleDatapoint, Timeseries } from "@cognite/sdk";
import { chunk, formatMeasurement } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useWindowSize } from "@/hooks/useWindowSize";
import { PAGE_LAYOUT_HEIGHT } from "@/utils/layout";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import {
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { isErrorWithMessage } from "@/utils/api";
import { retrieveTimeseriesByChunks } from "@/utils/cdf";
import { getCalculationFlows } from "../../../eepApi";
import { CompactContent } from "../../../components/CompactContent";
import { CalculationFlow, CalculationFlowNotification } from "../types";
import { StatusBadge } from "./StatusBadge";
import { ActionsMenu } from "./ActionsMenu";
import { getOutputExternalIds, getOutputNodes } from "./utils";

const ESTIMATED_TABLE_HEADER_HEIGHT = 45;

interface Props {
  flowType: "cloudAutomation" | "virtualSensor";
}

export function CalculationFlowTable({ flowType }: Props) {
  const { notification } = App.useApp();
  const t = useTranslations();

  useBuildingPageTitle(
    flowType === "cloudAutomation"
      ? t("calculation-flow.cloud-automation")
      : t("calculation-flow.virtual-sensors"),
  );
  const { height: windowHeight } = useWindowSize();
  const building = useCurrentBuilding();
  const currentBuildingId = building.id;
  const { client } = useCogniteClient();
  const params = useParams();

  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [flows, setFlows] = useState<CalculationFlow[]>([]);
  const [valueMap, setValueMap] = useState<
    Record<string, { value: number | string; timestamp?: Date; unit?: string }>
  >({});
  const [filteredFlows, setFilteredFlows] = useState<CalculationFlow[]>([]);
  const [loading, setLoading] = useState(false);
  const [timeseriesCache, setTimeseriesList] = useState<Timeseries[]>([]);
  const user = useUser();

  useEffect(() => {
    async function fetchAvailableFlows() {
      setLoading(true);
      try {
        if (currentBuildingId) {
          setFlows([]);
          const flows = await getCalculationFlows(
            Number(currentBuildingId),
            flowType,
          );
          const outputExternalIds = [
            ...new Set([
              ...flows
                .map((flow) => flow.health_ext_id)
                .filter((externalId): externalId is string => !!externalId),
              ...flows.map((flow) => getOutputExternalIds(flow)).flat(),
            ]),
          ];

          setTimeseriesList(
            (
              await retrieveTimeseriesByChunks(
                client,
                outputExternalIds.map((eid) => {
                  return { externalId: eid };
                }),
                { ignoreUnknownIds: true },
              )
            ).flat(),
          );
          setFlows(flows);
        }
      } catch (error) {
        if (isErrorWithMessage(error)) {
          return notification.error({
            message: error.message,
          });
        }
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    fetchAvailableFlows();
  }, [client, currentBuildingId, notification, flowType, params, setFlows, t]);

  useEffect(() => {
    if (flows.length > 0) {
      const outputExternalIds = [
        ...new Set(flows.map((flow) => getOutputExternalIds(flow)).flat()),
      ];

      if (outputExternalIds.length === 0) return;

      Promise.all(
        chunk(outputExternalIds, 100).map((chunk) => {
          return client.datapoints.retrieveLatest(
            chunk.map((externalId) => ({
              externalId: externalId!,
            })),
            { ignoreUnknownIds: true },
          );
        }),
      ).then((values) => {
        const newValues: Record<
          string,
          { value: number; timestamp: Date; unit?: string }
        > = {};
        values.flat().forEach((dp) => {
          const datapoint = dp.datapoints?.[0] as DoubleDatapoint;
          newValues[dp.externalId!] = {
            unit: dp.unit,
            value: datapoint?.value,
            timestamp: datapoint?.timestamp,
          };
        });
        setValueMap(newValues);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flows, setValueMap, flowType]);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) =>
    setQuery(event.target.value);

  const searchWords = useMemo(
    () => query.split(" ").filter((word) => word !== ""),
    [query],
  );

  useEffect(() => {
    if (searchWords.length === 0) setFilteredFlows(flows);
    else {
      setFilteredFlows(
        flows.filter((flow) => {
          return searchWords.some(
            (query) =>
              flow.name.toLowerCase().includes(query.toLowerCase()) ||
              flow.description.toLowerCase().includes(query.toLowerCase()) ||
              getOutputExternalIds(flow).some(
                (externalId) =>
                  externalId?.toLowerCase().includes(query.toLowerCase()) ||
                  (flow.user !== null &&
                    flow.user.toLowerCase().includes(query.toLowerCase())),
              ),
          );
        }),
      );
    }
    // disable exhaustive-deps because we want to ignore getOutputExternalId
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flows, searchWords]);

  return (
    <>
      <PageHeader
        title={
          flowType === "virtualSensor"
            ? t("calculation-flow.virtual-sensors")
            : t("calculation-flow.cloud-automation")
        }
        extra={
          <Space direction="horizontal">
            <Input
              placeholder={t("calculation-flow.search-placeholder")}
              onChange={handleSearch}
              allowClear
            />
            <Button
              data-testid="new-button"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate("new")}
              disabled={user.isViewer}
            >
              {t("calculation-flow.new")}
            </Button>
            <ToggleSidebarButton
              hiddenWhenSidebarVisible
              sidebarHiddenContent={t("notes.show-notes-button")}
            />
          </Space>
        }
      />
      <CompactContent>
        <TableWithoutDefaultSort
          pagination={false}
          dataSource={filteredFlows}
          rowKey="id"
          scroll={{
            y:
              windowHeight - PAGE_LAYOUT_HEIGHT - ESTIMATED_TABLE_HEADER_HEIGHT,
            x: "100%",
          }}
          columns={[
            {
              title: t("calculation-flow.table.name"),
              width: 200,
              ...(flowType === "cloudAutomation"
                ? {
                    defaultSortOrder: "ascend",
                  }
                : null),
              sorter: ({ name: nameOne }, { name: nameTwo }) =>
                sortString(nameOne, nameTwo),
              render: (_, { name }) => (
                <ProperateHighlighter
                  searchWords={searchWords}
                  textToHighlight={name}
                  title={name}
                />
              ),
            },
            {
              title: t("calculation-flow.table.description"),
              width: 200,
              sorter: (
                { description: descriptionOne },
                { description: descriptionTwo },
              ) => sortString(descriptionOne, descriptionTwo),
              render: (_, { description }) => (
                <ProperateHighlighter
                  searchWords={searchWords}
                  textToHighlight={description}
                  title={description}
                />
              ),
            },
            ...(flowType === "virtualSensor"
              ? [
                  {
                    title: t("calculation-flow.table.timeseries"),
                    defaultSortOrder: "ascend" as const,
                    width: 520,
                    sorter: (
                      { id: idOne }: CalculationFlow,
                      { id: idTwo }: CalculationFlow,
                    ) => sortString(idOne, idTwo),
                    render: (_: string, calculationFlow: CalculationFlow) => {
                      const outputExternalIds =
                        getOutputExternalIds(calculationFlow);
                      if (outputExternalIds.length === 0) return <></>;
                      return (
                        <>
                          {outputExternalIds.map((outputExternalId) => (
                            <>
                              <ProperateHighlighter
                                searchWords={searchWords}
                                textToHighlight={outputExternalId}
                                title={outputExternalId}
                              />
                              <ClipboardButton text={outputExternalId} />
                            </>
                          ))}
                        </>
                      );
                    },
                  },
                ]
              : []),
            {
              title: t("calculation-flow.table.status"),
              key: "status",
              align: "center",
              width: 50,
              sorter: (
                { notifications: notificationsOne, frequency: frequencyOne },
                { notifications: notificationsTwo, frequency: frequencyTwo },
              ) => {
                const getSortIndex = (
                  notifications: CalculationFlowNotification[],
                  frequency: number,
                ) => {
                  return frequency <= 0
                    ? -1
                    : notifications.filter((n) => n.type === "error").length *
                        1000000 +
                        notifications.filter((n) => n.type === "warning")
                          .length *
                          10000 +
                        notifications.filter((n) => n.type === "info").length *
                          100;
                };
                return (
                  getSortIndex(notificationsTwo, frequencyTwo) -
                  getSortIndex(notificationsOne, frequencyOne)
                );
              },
              render: (_, calculation_flow) => {
                return <StatusBadge calculationFlow={calculation_flow} />;
              },
            },
            {
              title:
                flowType === "cloudAutomation"
                  ? t("calculation-flow.table.last-values")
                  : t("calculation-flow.table.last-value"),
              key: "value",
              align: "right" as const,
              width: 100,
              sorter: (
                calculationFlowOne: CalculationFlow,
                calculationFlowTwo: CalculationFlow,
              ) => {
                if (
                  (calculationFlowOne.notifications.length > 0 ||
                    calculationFlowOne.frequency <= 0) &&
                  (calculationFlowTwo.notifications.length > 0 ||
                    calculationFlowTwo.frequency <= 0)
                ) {
                  return 0;
                }
                if (
                  calculationFlowOne.notifications.length > 0 ||
                  calculationFlowOne.frequency <= 0
                ) {
                  return -1;
                }
                if (
                  calculationFlowTwo.notifications.length > 0 ||
                  calculationFlowTwo.frequency <= 0
                ) {
                  return 1;
                }

                const valueOneId = getOutputExternalIds(calculationFlowOne)[0];
                const valueTwoId = getOutputExternalIds(calculationFlowTwo)[0];

                if (!valueOneId && !valueTwoId) {
                  return 0;
                }
                if (!valueOneId) {
                  return -1;
                }
                if (!valueTwoId) {
                  return 1;
                }

                const valueOne = valueMap[valueOneId];
                const valueTwo = valueMap[valueTwoId];

                if (valueOne === valueTwo) {
                  return 0;
                }
                if (valueOne === undefined) {
                  return -1;
                }
                if (!valueTwo === undefined) {
                  return 1;
                }

                if (
                  typeof valueOne.value === "string" &&
                  typeof valueTwo.value === "string"
                ) {
                  return valueOne.value.localeCompare(valueTwo.value);
                }

                if (typeof valueOne.value === "string") {
                  return -1;
                }
                if (typeof valueTwo.value === "string") {
                  return 1;
                }

                return valueOne.value - valueTwo.value;
              },
              render: (_: string, calculationFlow: CalculationFlow) => {
                if (calculationFlow.frequency <= 0) {
                  return "--";
                }
                const externalIds = getOutputExternalIds(calculationFlow);
                if (externalIds.length === 0) return <></>;

                return externalIds
                  .map((externalId) => {
                    const value = valueMap[externalId];
                    if (typeof value?.value === "string") {
                      return value.value;
                    }
                    return valueMap[externalId]
                      ? formatMeasurement({
                          value: valueMap[externalId].value as
                            | number
                            | undefined,
                          unit: valueMap[externalId].unit,
                        })
                      : "--";
                  })
                  .join("  ");
              },
            },
            ...(flowType === "virtualSensor"
              ? [
                  {
                    title: t("calculation-flow.table.unit"),
                    key: "unit",
                    width: 50,
                    align: "right" as const,
                    sorter: (
                      {
                        calculation_flow: { nodes: nodesOne },
                      }: CalculationFlow,
                      {
                        calculation_flow: { nodes: nodesTwo },
                      }: CalculationFlow,
                    ) => {
                      const unitOne = getOutputNodes(nodesOne)?.[0]?.data.unit;
                      const unitTwo = getOutputNodes(nodesTwo)?.[0]?.data.unit;
                      return sortString(unitOne, unitTwo);
                    },
                    render: (
                      _: string,
                      { calculation_flow: { nodes } }: CalculationFlow,
                    ) => {
                      return getOutputNodes(nodes)?.[0]?.data.unit?.replace(
                        "no-units",
                        "",
                      );
                    },
                  },
                ]
              : []),
            {
              title: t("calculation-flow.table.created-by"),
              sorter: ({ user: userOne }, { user: userTwo }) =>
                sortString(userOne, userTwo),
              dataIndex: "user",
              width: 170,
              render: (_, { user }) =>
                user && (
                  <ProperateHighlighter
                    searchWords={searchWords}
                    textToHighlight={user}
                  />
                ),
            },
            {
              key: "actions",
              title: t("calculation-flow.table.actions"),
              width: 250,
              render: (_, calculationFlow) => (
                <ActionsMenu
                  calculationFlow={calculationFlow}
                  afterDelete={(deletedFlow) =>
                    setFlows(flows.filter((flow) => flow !== deletedFlow))
                  }
                  timeseriesCache={timeseriesCache}
                />
              ),
            },
          ]}
          loading={loading}
        />
        {building.dataSetId && (
          <NotesSidebar
            noteSource={
              flowType === "virtualSensor"
                ? NoteSource.WEB_VIRTUAL_SENSORS
                : NoteSource.WEB_CLOUD_AUTOMATIONS
            }
            assetFilterMode={NotesAssetFilterMode.AssetList}
            idSet={
              new Set(
                timeseriesCache
                  .filter((ts) => ts.assetId !== null)
                  .map((ts) => ts.assetId as number),
              )
            }
            buildings={[{ id: building.dataSetId, name: building.name }]}
          />
        )}
      </CompactContent>
    </>
  );
}
