import { MessageKey, useTranslations } from "@properate/translations";
import { Button, Divider, Form, FormInstance, Select } from "antd";
import { ReactNode, useState } from "react";
import {
  AlarmRule,
  getUserNameWithEmail,
  IncidentClientSide,
} from "@properate/common";
import dayjs from "@properate/dayjs";
import { BasicAccordionItem } from "@properate/ui";
import { ExportOutlined } from "@ant-design/icons";
import { AlarmSummary } from "@/pages/alarms/details/AlarmDeviationsPage/AlarmSummary";
import {
  useIncidentComments,
  useIncidentRelatedDatapoints,
} from "@/pages/alarmSystem/incident/utils";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { IncidentTimer } from "./IncidentTimer";
import { IncidentActivity } from "./IncidentActivity";

type Props = {
  assignButtons: ReactNode[];
  data: IncidentClientSide;
  name: string;
  refetchIncident: () => void;
  onUpdateIncident: (values: Partial<IncidentClientSide>) => Promise<void>;
  form: FormInstance;
  alarmRule?: AlarmRule;
};

export const IncidentInfo = ({
  assignButtons,
  data,
  name,
  refetchIncident,
  onUpdateIncident,
  form,
  alarmRule,
}: Props) => {
  const t = useTranslations();
  const currentBuildingId = useCurrentBuildingId();

  useIncidentRelatedDatapoints(data.start, alarmRule);

  const isAssigned = !!data.assigned?.user;
  const canChangeStatus = data.status !== "resolved";

  const statusSelectOptions = [
    {
      label: t("incident.statuses.unresolved"),
      value: "unresolved",
    },
    {
      label: t("incident.statuses.in_progress"),
      value: "in_progress",
    },
    {
      label: t("incident.statuses.resolved"),
      value: "resolved",
    },
  ];

  const [isOpen, setIsOpen] = useState(false);

  const { comments, isLoadingComments, isLoadingEvent, onSendComment } =
    useIncidentComments(data);

  const onFinish = async ({
    status,
  }: {
    status: IncidentClientSide["status"];
  }) => {
    const comment = t("incident.status-changed-message", {
      status: t(`incident.statuses.${status}` as MessageKey),
      user: getUserNameWithEmail(data.responsible?.user),
    });
    await onUpdateIncident({ last_comment: comment, status });
    await onSendComment(comment, undefined, false);
  };

  return (
    <div className="flex flex-col">
      <div className="flex gap-3 items-center">
        <h2 className="mb-0">{t("incident.incident-information")} </h2>
        {!!data.has_active_event && (
          <div className="w-3 h-3 bg-red-500 rounded-full" />
        )}
      </div>
      <Divider className="my-4" />
      {!isAssigned && (
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">{assignButtons}</div>
          {data.alert_next_at && (
            <IncidentTimer
              refetchIncident={refetchIncident}
              sendToNextAt={data.alert_next_at}
            />
          )}
          <div className="flex gap-2">
            <div>{t("incident.responsible")}:</div>
            <div className="font-bold">
              {getUserNameWithEmail(data.responsible?.user) ||
                (data.notified_people?.user.length
                  ? data.notified_people?.user
                      .map((user) => getUserNameWithEmail(user))
                      .join(", ")
                  : t("incident.no-responsible-people"))}
            </div>
          </div>
        </div>
      )}
      {isAssigned && (
        <div className="flex gap-2">
          <div>{t("incident.assigned-to")}:</div>
          <div className="font-bold">
            {getUserNameWithEmail(data.assigned?.user)}
          </div>
        </div>
      )}
      <div className="my-6 flex flex-col gap-2">
        <div className="flex gap-2">
          <div>{t("incident.name")}:</div>
          <div className="font-bold">{name}</div>
        </div>
        <div className="flex gap-2">
          <div>{t("incident.reported-at")}:</div>
          <div>{dayjs(data.start).format("DD/MM YYYY HH:mm")}</div>
        </div>
        <div className="flex gap-2">
          <div>{t("incident.status")}:</div>
          {canChangeStatus ? (
            <Form
              form={form}
              id="incidents-form"
              initialValues={{
                status: data.status,
              }}
              onFinish={onFinish}
            >
              <Form.Item name="status">
                <Select className="w-40" options={statusSelectOptions} />
              </Form.Item>
            </Form>
          ) : (
            t(`incident.statuses.${data.status}` as MessageKey)
          )}
        </div>
      </div>
      <Divider className="my-4" />
      <BasicAccordionItem
        hasPaddings={false}
        titleClassName="text-xl"
        openClassName=""
        open={isOpen}
        setOpen={setIsOpen}
        htmlId="incident_alarm"
        title={
          <>
            {t("incident.alarm")}
            <Button
              onClick={() => {
                window.open(
                  `/asset/${currentBuildingId}/newAlarms/${data.alarm_rule_id}`,
                  "_blank",
                  "noopener,noreferrer",
                );
              }}
              type="link"
              icon={<ExportOutlined />}
            />
          </>
        }
        primaryOpenContent={<AlarmSummary />}
      />
      <Divider className="my-4" />
      <IncidentActivity
        comments={comments}
        isLoadingComments={isLoadingComments}
        isLoadingEvent={isLoadingEvent}
        sendComment={onSendComment}
      />
    </div>
  );
};
