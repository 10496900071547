import { useTranslations } from "@properate/translations";
import { BaseVirtualSetpoint } from "../../components/MeterSelectionModal/types";
import { WhiteModal } from "./elements";
import { VirtualSetPointCreationForm } from "./VirtualSetPointCreationForm";

interface Props {
  open: boolean;
  virtualSetpoint: BaseVirtualSetpoint | null;
  onOk: (meter: BaseVirtualSetpoint) => void;
  onHide: () => void;
  isLoading?: boolean;
}

export function VirtualSetPointCreationModal({
  open,
  virtualSetpoint,
  onOk,
  onHide,
  isLoading = false,
}: Props) {
  const t = useTranslations();

  function handleFinish(
    virtualSetpointValues: Pick<
      BaseVirtualSetpoint,
      | "subBuilding"
      | "system"
      | "unit"
      | "min_value"
      | "max_value"
      | "description"
    >,
  ) {
    if (virtualSetpoint) {
      onOk({
        ...virtualSetpoint,
        ...virtualSetpointValues,
      });
    }
  }

  return (
    <WhiteModal
      title={<h3>{t("writable.virtual-set-point-creation-modal.title")}</h3>}
      centered
      open={open}
      onCancel={onHide}
      footer={null}
      destroyOnClose
      width={800}
    >
      {virtualSetpoint && (
        <VirtualSetPointCreationForm
          virtualSetpoint={virtualSetpoint}
          onFinish={handleFinish}
          isLoading={isLoading}
          onCancel={onHide}
        />
      )}
    </WhiteModal>
  );
}
