import { Select } from "antd";
import {
  keysForAlarmPriority,
  SimplifiedAlarmPriority,
} from "@properate/common";
import { useTranslations, MessageKey } from "@properate/translations";

type Props = {
  onChange: (severity: SimplifiedAlarmPriority) => void;
  severity?: SimplifiedAlarmPriority;
};

export const IncidentSeverityFilter = (props: Props) => {
  const t = useTranslations();

  const options = [
    SimplifiedAlarmPriority.Low,
    SimplifiedAlarmPriority.Medium,
    SimplifiedAlarmPriority.High,
    SimplifiedAlarmPriority.Critical,
  ].map((level) => ({
    label: t(`${keysForAlarmPriority[level]}` as MessageKey),
    value: level,
  }));

  return (
    <Select
      allowClear
      className="w-40"
      value={props.severity}
      onChange={props.onChange}
      options={options}
      placeholder={t("incidents.filter-by-severity")}
    />
  );
};
