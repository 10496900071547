import { useUser } from "@properate/auth";
import { Col, Form, Row, Tag } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import { UnitSelect } from "./UnitSelect";
import { BBSelect } from "./BBSelect";

const FormItem = Form.Item;

interface Props {
  subBuilding: string | null;
  system: string | null;
  bb: string;
  unit: string;
  labels: string[];
  onUnitChange: (unit: string) => void;
  onBBChange: (bb: string) => void;
}

function TimeseriesDetails({
  subBuilding,
  system,
  bb,
  unit,
  labels,
  onUnitChange,
  onBBChange,
}: Props) {
  const t = useTranslations();

  const user = useUser();

  const assetDescription =
    Number(system?.substring(0, 3)) === 0
      ? t("calculation-flow.virtual-sensors")
      : t(
          `tfm.system.buildingPartNumber.${Number(
            system?.substring(0, 3),
          )}` as MessageKey,
        );

  return (
    <Form
      layout="vertical"
      style={{ paddingLeft: "6px", paddingRight: "6px" }}
      disabled={user.isViewer}
    >
      <Row gutter={24}>
        <Col span={8}>
          <FormItem label={t("calculation-flow.meter-details.sub-building")}>
            {subBuilding}
          </FormItem>
        </Col>
        {system !== null && (
          <Col span={8}>
            <FormItem label={t("calculation-flow.meter-details.system")}>
              {system}
              <br />({assetDescription})
            </FormItem>
          </Col>
        )}
        <Col span={8}>
          <FormItem label={t("calculation-flow.meter-details.component")}>
            <BBSelect value={bb} onChange={onBBChange} hidden={["OE", "RF"]} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label={t("calculation-flow.meter-details.unit")}>
            <UnitSelect value={unit} onChange={onUnitChange} />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem label={t("calculation-flow.meter-details.labels")}>
            {labels.map((label) => (
              <Tag key={label}>{label}</Tag>
            ))}
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
}

export default TimeseriesDetails;
