import {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

type SimpleGraphContextType = {
  hiddenTimeseriesIds: number[];
  setHiddenTimeseriesIds: Dispatch<SetStateAction<number[]>>;
};

export const SimpleGraphContext = createContext<SimpleGraphContextType>({
  hiddenTimeseriesIds: [],
  setHiddenTimeseriesIds: () => console.warn("Not implemented"),
});

export function SimpleGraphContextProvider(props: PropsWithChildren) {
  const [hiddenTimeseriesIds, setHiddenTimeseriesIds] = useState<number[]>([]);

  return (
    <SimpleGraphContext.Provider
      value={{
        hiddenTimeseriesIds,
        setHiddenTimeseriesIds,
      }}
    >
      {props.children}
    </SimpleGraphContext.Provider>
  );
}

export function useSimpleGraphContext() {
  return useContext(SimpleGraphContext);
}
