import { Timeseries } from "@cognite/sdk";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { ThirdPartyAlarmVisualTimeseries } from "@properate/common";
import {
  AutoSizedSimpleGraph,
  SimpleGraphContextProvider,
} from "@/features/analysis";
import {
  useAlarmDetailsTimeSpan,
  useAlarmGraphLegendData,
  useGraphHighlightsForEvents,
  useTimeseries,
} from "../../../hooks";
import { AlarmTimeseriesNavigator } from "../../../components/AlarmTimeseriesGraph/AlarmTimeseriesNavigator";
import { getColor } from "../../../components/AlarmTimeseriesGraph/utils";
import { AlarmTimeseriesGraphLayout } from "../../../components/AlarmTimeseriesGraph/AlarmTimeseriesGraphLayout";
import { useFormValue } from "../../../FormContext";
import { ThirdPartyAlarmFields } from "../types";
import AlarmTimeseriesEmpty from "../../../components/AlarmTimeseriesGraph/AlarmTimeseriesEmpty";
import {
  useBaseIsAlsoVisual,
  useThirdPartyAlarmTimeseriesListWithColors,
} from "../hooks";

function ThirdPartyAlarmGraphImpl() {
  const themeContext = useContext(ThemeContext);
  const { timeseriesList = [] } = useThirdPartyAlarmTimeseriesListWithColors();
  const [baseTimeseriesId] = useFormValue<number | undefined>(
    ThirdPartyAlarmFields.BaseTimeseries,
  );
  const { timeseries } = useTimeseries({
    timeseriesId: baseTimeseriesId,
  });
  const baseTimeseriesIsAlsoVisual = useBaseIsAlsoVisual();
  const timeSpan = useAlarmDetailsTimeSpan();
  const highlights = useGraphHighlightsForEvents();

  const [visualTimeseries] = useFormValue<ThirdPartyAlarmVisualTimeseries[]>(
    ThirdPartyAlarmFields.VisualTimeseries,
  );

  const renderableTimeseriesList = timeseriesList.filter(
    ({ timeseries }) =>
      baseTimeseriesIsAlsoVisual || timeseries.id !== baseTimeseriesId,
  );

  const legendData = useAlarmGraphLegendData({
    timeseriesList: renderableTimeseriesList.map(
      ({ timeseries }) => timeseries,
    ),
    timeseriesSettings: renderableTimeseriesList.reduce((acc, item) => {
      const vt = visualTimeseries.find((vt) => vt.value === item.timeseries.id);

      return {
        ...acc,
        [item.timeseries.id]: {
          color: item.color,
          labelKey: vt
            ? `alarm-details.alarm-types.third-party-alarm.visual-timeseries.type.${vt.label}`
            : undefined,
        },
      };
    }, {}),
  });

  function renderGraph() {
    return (
      <AutoSizedSimpleGraph
        interactive
        mergeUnits
        highlights={highlights}
        timeSpan={timeSpan}
        legendData={legendData}
        settingsTimeseriesList={renderableTimeseriesList.map(
          ({ color, timeseries }) => ({
            id: timeseries.id,
            hidden: false,
            color,
            unitSelected: timeseries?.unit ?? "",
          }),
        )}
      />
    );
  }

  function renderNavigator() {
    const tslist = [
      {
        timeseries: timeseries as Timeseries,
        color: getColor(0, themeContext),
      },
    ];
    return <AlarmTimeseriesNavigator timeseriesWithDataPointsList={tslist} />;
  }

  if (!timeseriesList || !timeseries) {
    return <AlarmTimeseriesEmpty />;
  }

  return (
    <AlarmTimeseriesGraphLayout
      graph={renderGraph()}
      navigator={renderNavigator()}
    />
  );
}

export function ThirdPartyAlarmGraph() {
  return (
    <SimpleGraphContextProvider>
      <ThirdPartyAlarmGraphImpl />
    </SimpleGraphContextProvider>
  );
}
