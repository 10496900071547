import { createContext, RefObject, useContext, useMemo } from "react";
import { PanZoomApi } from "@sasza/react-panzoom/types/types";

type Context = {
  current: RefObject<PanZoomApi | null>;
};

const context = createContext<Context>({
  current: {
    current: null,
  },
});

type Props = {
  apiRef: RefObject<PanZoomApi | null>;
  children: React.ReactNode;
};

export function PanZoomAreaApiProvider(props: Props) {
  const value = useMemo(() => {
    return {
      current: props.apiRef,
    };
  }, [props.apiRef]);

  return <context.Provider value={value}>{props.children}</context.Provider>;
}

export function usePanZoomAreaApi() {
  const { current: apiRef } = useContext(context);

  return apiRef.current;
}
