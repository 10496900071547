import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { Timeseries } from "@cognite/sdk";
import { convertValue, DERIVED_UNITS, SensorInfo } from "@properate/common";
import { Button, Form, Modal, Radio, Select, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import * as React from "react";
import { useTranslations } from "@properate/translations";
import { formatUnit } from "@/pages/common/SchemaView/TechnicalSchema/utils";
import { formatExtendedTimeseriesDescription } from "@/utils/helpers";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { AuditLogModal } from "@/components/AuditLog";

type Props = {
  open: boolean;
  onHide: () => void;
  timeseriesInfo: Pick<SensorInfo, "id">;
  showSettings: Function;
  removeTimeseries: () => void;
  setPointStatus: { priority: number | null };
  setPriority: (externalId: string, priority: number) => void;
};

export const SetPointSettings = ({
  open,
  timeseriesInfo,
  onHide,
  showSettings,
  removeTimeseries,
  setPointStatus,
  setPriority,
}: Props) => {
  const t = useTranslations();
  const { client } = useCogniteClient();
  const currentBuilding = useCurrentBuilding();
  const { overrideUnits, handleOverrideUnit } = useTimeseriesSettings(
    currentBuilding.id,
  );
  const [form] = useForm();
  const [onChangeOverrideUnit, setOnChangeOverrideUnit] = React.useState<
    string | undefined
  >();
  const [timeseries, setTimeseries] = React.useState<Timeseries | undefined>();
  const [showHistory, setShowHistory] = useState(false);

  useEffect(() => {
    const get = async () => {
      const ts = await client.timeseries.retrieve([{ id: timeseriesInfo.id }]);
      if (ts.length > 0) {
        setTimeseries(ts[0]);
      }
    };

    get();
  }, [timeseriesInfo, client]);

  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  return (
    <Modal
      open={open}
      onCancel={onHide}
      confirmLoading={isConfirmLoading}
      onOk={() => form.submit()}
      title={t("calculation-flow.setpoints.settings")}
    >
      <Space direction="vertical">
        <Space>
          {timeseries && formatExtendedTimeseriesDescription(timeseries)}
          <Button onClick={() => showSettings()} icon={<EditOutlined />} />
          <Button
            icon={<HistoryOutlined />}
            onClick={() => setShowHistory((prevState) => !prevState)}
          />
          <Button onClick={removeTimeseries} icon={<DeleteOutlined />} danger />
        </Space>
        <Space>
          {t("calculation-flow.setpoints.id")} {timeseries?.externalId}
        </Space>
        {(timeseries?.metadata?.min_value ||
          timeseries?.metadata?.max_value) && (
          <Space>
            {timeseries &&
              timeseries.metadata?.min_value &&
              t("calculation-flow.setpoints.min-value", {
                value: convertValue(
                  timeseries.unit || "",
                  onChangeOverrideUnit ||
                    overrideUnits?.[timeseries.externalId!]?.unit ||
                    timeseries.unit ||
                    "",
                  Number(timeseries.metadata?.min_value),
                ),
              })}
            {timeseries &&
              timeseries.metadata?.max_value &&
              t("calculation-flow.setpoints.max-value", {
                value: convertValue(
                  timeseries.unit || "",
                  onChangeOverrideUnit ||
                    overrideUnits?.[timeseries.externalId!]?.unit ||
                    timeseries.unit ||
                    "",
                  timeseries.metadata?.max_value
                    ? Number(timeseries.metadata.max_value)
                    : undefined,
                ),
              })}
          </Space>
        )}
        {typeof timeseries === "object" && (
          <Form
            initialValues={{
              unit:
                (timeseries &&
                  overrideUnits &&
                  overrideUnits[timeseries.externalId!]?.unit) ||
                timeseries.unit,
              priority: setPointStatus.priority,
            }}
            form={form}
            onFinish={async (values) => {
              setIsConfirmLoading(true);

              try {
                const overrideUnit =
                  timeseries &&
                  overrideUnits &&
                  overrideUnits[timeseries.externalId!]?.unit;
                if (values.unit !== overrideUnit) {
                  await handleOverrideUnit(
                    currentBuilding.id,
                    timeseries.externalId!,
                    values.unit,
                    overrideUnit,
                  );
                }
                if (values.priority !== setPointStatus.priority) {
                  await setPriority(timeseries.externalId!, values.priority);
                }

                onHide();
              } finally {
                setIsConfirmLoading(false);
              }
            }}
          >
            <Form.Item label={t("calculation-flow.setpoints.unit")} name="unit">
              <Radio.Group
                value={
                  onChangeOverrideUnit || (timeseries?.unit && timeseries.unit)
                }
                buttonStyle="solid"
                onChange={(event) => {
                  setOnChangeOverrideUnit(event.target.value);
                }}
              >
                <Radio.Button value={timeseries.unit || ""}>
                  {timeseries?.unit && formatUnit(timeseries.unit)}
                </Radio.Button>
                {timeseries?.unit &&
                  Object.keys(DERIVED_UNITS[timeseries.unit] || {}).map(
                    (key) => (
                      <Radio.Button value={key} key={key}>
                        {formatUnit(key)}
                      </Radio.Button>
                    ),
                  )}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={t("calculation-flow.setpoints.priority")}
              name="priority"
            >
              <Select
                style={{ width: 100 }}
                loading={setPointStatus.priority === null}
              >
                <Select.Option value={16}>16</Select.Option>
                <Select.Option value={8}>8</Select.Option>
                {setPointStatus.priority !== 8 &&
                setPointStatus.priority !== 16 ? (
                  <Select.Option value={setPointStatus.priority}>
                    {setPointStatus.priority}
                  </Select.Option>
                ) : null}
              </Select>
            </Form.Item>
          </Form>
        )}
      </Space>
      {showHistory && timeseries?.externalId && (
        <AuditLogModal
          name={formatExtendedTimeseriesDescription(timeseries)}
          externalId={timeseries.externalId}
          onHide={() => setShowHistory(false)}
        />
      )}
    </Modal>
  );
};
