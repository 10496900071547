import { useState } from "react";
import { useTranslations } from "@properate/translations";
import {
  AlarmRuleTypeName,
  CompareTimeseriesAlarmRule,
  PartialAlarmRule,
} from "@properate/common";
import { DescriptionListEntry, RichTextFormat } from "@properate/ui";
import { useAlarmTypeDescription } from "@/pages/alarms/details/hooks/useAlarmTypeDescription";
import { TimeseriesAccordionBox } from "@/pages/alarms/details/components/TimeseriesDescriptionListAccordionBox";
import {
  AlarmDeviationTable,
  DeviationTableColumn,
} from "@/pages/alarms/details/components/AlarmDeviationTable";
import { AlarmGraphFormFields } from "@/pages/alarms/details/components/AlarmTimeseriesGraph";
import { AlarmsSeparator } from "../components/layout";
import {
  SelectedTimeseries,
  SelectTimeseries,
} from "../components/TimeseriesSelector";
import { numberValidator } from "../FormContext/validators";
import { useFormValue } from "../FormContext/hooks";
import { useTimeseries } from "../hooks";
import { getSelectTimeseriesEntries } from "../components/TimeseriesSelector/SelectTimeseries";
import { getSubmitValueEntry } from "../FormContext/utils";
import { requiredSelect, tKey } from "./common/utils";
import {
  getConditionOffsetAlarmRuleFields,
  getSelectOffsetFieldDefinitions,
  getTypeSpecificOffsetAlarmRuleFields,
  OffsetMode,
  SelectOffset,
} from "./common/SelectOffset";
import { AlarmRuleType, TypeDescriptionProps } from "./index";

export enum CompareTimeseriesFormFields {
  BaseTimeseries = "CompareTimeseriesBaseTimeseries",
  ComparisonTimeseries = "CompareTimeseriesComparisonTimeseries",
}

export enum CompareTimeseriesViewState {
  Default,
  SelectBase,
  SelectComparison,
}

function CompareTimeseriesMessage({
  disabledFormatters,
  formatOverrides,
  hasDuration,
}: TypeDescriptionProps) {
  return (
    <RichTextFormat
      message={
        hasDuration
          ? "alarm-details.alarm-types.compare-timeseries.type-description-with-duration"
          : "alarm-details.alarm-types.compare-timeseries.type-description"
      }
      disabledFormatters={disabledFormatters}
      formatOverrides={formatOverrides}
    />
  );
}

export const CompareTimeseriesDefinition: AlarmRuleType = {
  name: AlarmRuleTypeName.CompareTimeseries,
  labelTranslationKey: tKey(`compare-timeseries.type-label`),
  TypeDescription: CompareTimeseriesMessage,
  getFormFields: (alarmRule: PartialAlarmRule) => {
    return {
      [CompareTimeseriesFormFields.BaseTimeseries]: {
        defaultValue:
          alarmRule?.condition?.type_specific?.base_timeseries_id ?? undefined,
        getValidator: (t) =>
          numberValidator(
            t(requiredSelect, {
              fieldName: t(tKey(`compare-timeseries.base`)).toLowerCase(),
            }),
          ),
      },
      [CompareTimeseriesFormFields.ComparisonTimeseries]: {
        defaultValue:
          (alarmRule as CompareTimeseriesAlarmRule)?.condition?.type_specific
            ?.comparison_timeseries_id ?? undefined,
        getValidator: (t) =>
          numberValidator(
            t(requiredSelect, {
              fieldName: t(tKey(`compare-timeseries.compare`)).toLowerCase(),
            }),
          ),
      },
      ...getSelectOffsetFieldDefinitions({
        alarmRule,
        mode: OffsetMode.Compare,
      }),
      ...getSelectTimeseriesEntries(),
    };
  },
  getAlarmRuleFields: ({ entries }): PartialAlarmRule => {
    return {
      condition: {
        ...getConditionOffsetAlarmRuleFields({ entries }),
        type_specific: {
          ...getTypeSpecificOffsetAlarmRuleFields({ entries }),
          comparison_timeseries_id: getSubmitValueEntry<number>(
            entries,
            CompareTimeseriesFormFields.ComparisonTimeseries,
          ),
          base_timeseries_id: getSubmitValueEntry<number>(
            entries,
            CompareTimeseriesFormFields.BaseTimeseries,
          ),
        },
      },
    };
  },
  formComponent: <CompareTimeseries />,
  summaryContents: {
    generalAlarmMetaFields: <CompareTimeseriesAlarmMetaFields />,
    typeSpecificSummary: <CompareTimeseriesTypeSpecificSummary />,
  },
  getDeviationsTable: (props) => (
    <AlarmDeviationTable
      columns={[
        DeviationTableColumn.Timeframe,
        DeviationTableColumn.Value,
        DeviationTableColumn.Threshold,
      ]}
      {...props}
    />
  ),
};

export function CompareTimeseries() {
  const t = useTranslations();
  const [viewState, setViewState] = useState<CompareTimeseriesViewState>(
    CompareTimeseriesViewState.Default,
  );
  const [timeseriesId] = useFormValue<number | undefined>(
    CompareTimeseriesFormFields.BaseTimeseries,
  );
  const { timeseries } = useTimeseries({
    timeseriesId,
  });
  const [_showGraph, setShowGraph] = useFormValue<boolean>(
    AlarmGraphFormFields.GraphVisible,
  );

  function closeSelector() {
    setShowGraph(true);
    setViewState(CompareTimeseriesViewState.Default);
  }

  function getActivateSelector(viewState: CompareTimeseriesViewState) {
    return () => {
      setShowGraph(false);
      setViewState(viewState);
    };
  }

  switch (viewState) {
    case CompareTimeseriesViewState.SelectBase:
      return (
        <SelectTimeseries
          close={closeSelector}
          name={CompareTimeseriesFormFields.BaseTimeseries}
          label={t(tKey(`compare-timeseries.select-base`))}
        />
      );
    case CompareTimeseriesViewState.SelectComparison:
      return (
        <SelectTimeseries
          close={closeSelector}
          name={CompareTimeseriesFormFields.ComparisonTimeseries}
          label={t(tKey(`compare-timeseries.select-compare`))}
        />
      );
    case CompareTimeseriesViewState.Default:
    default:
      return (
        <>
          <SelectedTimeseries
            name={CompareTimeseriesFormFields.BaseTimeseries}
            label={t(tKey(`compare-timeseries.base`))}
            activateSelector={getActivateSelector(
              CompareTimeseriesViewState.SelectBase,
            )}
          />
          <AlarmsSeparator direction={"horizontal"} />
          <SelectedTimeseries
            name={CompareTimeseriesFormFields.ComparisonTimeseries}
            label={t(tKey(`compare-timeseries.compare`))}
            activateSelector={getActivateSelector(
              CompareTimeseriesViewState.SelectComparison,
            )}
          />
          <AlarmsSeparator direction={"horizontal"} />
          <SelectOffset unit={timeseries?.unit ?? ""} />
        </>
      );
  }
}

export function CompareTimeseriesAlarmMetaFields() {
  const t = useTranslations("alarm-details.deviations-page.summary");
  const [timeseriesId] = useFormValue<number>(
    CompareTimeseriesFormFields.BaseTimeseries,
  );

  const typeDescription = useAlarmTypeDescription({
    timeseriesId,
    noBold: true,
  });

  return (
    <DescriptionListEntry
      termColon
      term={t("type")}
      description={typeDescription}
    />
  );
}

export function CompareTimeseriesTypeSpecificSummary() {
  const [baseTimeseriesId] = useFormValue<number>(
    CompareTimeseriesFormFields.BaseTimeseries,
  );
  const [comparisonTimeseriesId] = useFormValue<number>(
    CompareTimeseriesFormFields.ComparisonTimeseries,
  );
  const t = useTranslations("alarm-details.alarm-types.compare-timeseries");
  return (
    <div className="w-full flex flex-col flex-nowrap gap-4">
      <TimeseriesAccordionBox
        timeseriesId={baseTimeseriesId}
        title={t("base")}
      />
      <TimeseriesAccordionBox
        timeseriesId={comparisonTimeseriesId}
        title={t("compare")}
      />
    </div>
  );
}
