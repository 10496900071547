import { useState } from "react";
import { AutoComplete, Input } from "antd";
import { useTranslations } from "@properate/translations";
import { useFacets } from "@/pages/Timeseries/useFacets";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";

interface Props {
  onChangeSystemFilter: (value: string | null) => unknown;
  translatedLabelsFilter: string[];
  subBuildingFilter: string | null;
}
export default function SystemFacets({
  onChangeSystemFilter,
  translatedLabelsFilter,
  subBuildingFilter,
}: Props) {
  const currentBuildingId = useCurrentBuildingId();
  const [systemQuery, setSystemQuery] = useState<string>("");
  const t = useTranslations();
  const { data: systemFacets } = useFacets(
    "system",
    {
      buildingId: currentBuildingId,
      system: null,
      translatedLabels: translatedLabelsFilter,
      subBuilding: subBuildingFilter,
    },
    undefined,
    systemQuery,
  );

  return (
    <AutoComplete
      key="selectedSystems"
      options={
        systemFacets &&
        Object.entries(systemFacets)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map((system) => {
            return {
              value: system[0],
              label: `${system[0]} (${system[1]})`,
            };
          })
      }
      style={{
        width: 150,
      }}
      onSelect={onChangeSystemFilter}
      onSearch={(text) => setSystemQuery(text)}
    >
      <Input
        placeholder={t("timeseries.toolbar.filter-by-system-placeholder")}
        allowClear
        onChange={(e) => {
          if (!e.target.value) {
            setSystemQuery("");
            onChangeSystemFilter(null);
          }
        }}
      />
    </AutoComplete>
  );
}
